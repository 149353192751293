import { Icon, Text } from 'react-native-paper';
import { useGetSubscriptionPortalSession } from '../api/subscription';
import BottleLoader from '../components/BottleLoader';
import { useEffect, useRef, useState } from 'react';
import { Linking, View } from 'react-native';
import { getLocaleString } from '../utils/getLocaleString';
import BottleButton from '../components/BottleButton';
import BottleScreen from '../components/BottleScreen';


const Inactive = () => {
  const isMounted = useRef(false);
  const [isLoading, setIsLoading] = useState(true);
  const {data: portalSession} = useGetSubscriptionPortalSession();

  useEffect(() => {
    if (!isMounted.current && portalSession?.url) {
      isMounted.current = true;
      openPortalSession();
    }
  }, [portalSession]);

  const openPortalSession = () => {
    if (portalSession?.url) {
      Linking.openURL(portalSession.url)
        .then(() => setIsLoading(false));
    }
  };

  return isLoading ? (
    <BottleLoader size={'large'} />
  ) : (
    <BottleScreen>
      <View
        style={{
          width: '100%',
          maxWidth: 500,
          margin: 'auto',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Icon source="credit-card-off" size={76} />
        <Text
          variant="titleLarge"
          style={{marginTop: 20, marginBottom: 48, textAlign: 'center'}}>
          {getLocaleString('pleaseReviewYourSubscription')}
        </Text>
        <Text
          variant="labelLarge"
          style={{marginBottom: 16, textAlign: 'center'}}>
          {getLocaleString('youShouldBeRedirected')}
        </Text>
        <BottleButton mode="contained" onPress={openPortalSession}>
          {getLocaleString('viewSubscription')}
        </BottleButton>
      </View>
    </BottleScreen>
  );
}

export default Inactive;
