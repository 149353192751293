import { useState } from "react";
import { StyleSheet, View } from "react-native";
import { Title, TextInput, Subheading, useTheme } from "react-native-paper";
import { getLocaleString } from "../../utils/getLocaleString";
import BottleButton from "../../components/BottleButton";
import { StackNavigationProp } from "@react-navigation/stack";
import { AuthStackParamList } from "./types";
import AuthScreen from "./AuthScreen";
import { autoSignIn, confirmSignUp, resendSignUpCode } from "aws-amplify/auth";


type VerifyEmailProps = {
  email: string;
  navigation: StackNavigationProp<AuthStackParamList>;
  onDone?: () => Promise<void>
};


const VerifyEmail = ({email, navigation, onDone}: VerifyEmailProps) => {
  const [error, setError] = useState('');
  const [code, setCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);

  const {colors} = useTheme();

  const verifyDisabled = !code || loading || resendLoading;

  const resend = async () => {
    setResendLoading(true);
    await resendSignUpCode({username: email});
    setResendLoading(false);
  }

  const submit = async () => {
    setLoading(true);
    setError('');

    try {
      const {nextStep} = await confirmSignUp({
        username: email,
        confirmationCode: code,
      });
      switch (nextStep.signUpStep) {
        case 'DONE':
          if (onDone) {
            await onDone();
          }
          break;
        case 'COMPLETE_AUTO_SIGN_IN':
          await autoSignIn();
      }
    } catch (error) {
      switch ((error as {name: string}).name) {
        case 'CodeMismatchException':
          setError(getLocaleString('invalidVerificationCode'));
          setCode('');
          break;
        default:
          navigation.push('SignIn', {
            error: getLocaleString('sww'),
          });
          break;
      }
    }
    setLoading(false);
  };

  return (
    <AuthScreen>
      <Title style={styles.text}>
        {getLocaleString('checkEmailForVerificationCode')}
      </Title>

      {!!error && (
        <Subheading style={{color: colors.error, textAlign: 'center'}}>
          {error}
        </Subheading>
      )}

      <TextInput
        autoComplete="off"
        autoFocus={true}
        dense={true}
        label={getLocaleString('code')}
        mode="outlined"
        onChangeText={setCode}
        value={code}
        disabled={loading}
        blurOnSubmit={true}
        onSubmitEditing={submit}
        returnKeyType="send"
        style={styles.field}
      />

      <BottleButton
        disabled={verifyDisabled}
        loading={loading}
        mode="contained"
        onPress={submit}>
        {getLocaleString('verifyEmail')}
      </BottleButton>

      <View style={{marginTop: 20, alignItems: 'center'}}>
        <BottleButton
          disabled={loading || resendLoading}
          loading={resendLoading}
          mode="text"
          onPress={resend}>
          {getLocaleString('resendCode')}
        </BottleButton>
      </View>
    </AuthScreen>
  );
};

const styles = StyleSheet.create({
  field: {
    marginBottom: 10,
  },
  text: {
    textAlign: "center",
  },
});

export default VerifyEmail;
