import {ScrollView, StyleSheet, View} from 'react-native';
import {useGetSettings, useUpdateSettings} from '../../api/settings';
import {Switch, Text} from 'react-native-paper';
import {getLocaleString} from '../../utils/getLocaleString';
import {useEffect, useState} from 'react';
import {SettingsRequestModel, SettingsResponseModel} from '../../api/openapi';
import BottleTitle from '../../components/BottleTitle';

const NotificationSettings = () => {
  const {isLoading, data} = useGetSettings();
  const updateSettings = useUpdateSettings();
  const [settings, setSettings] = useState<SettingsResponseModel>();

  useEffect(() => {
    if (!isLoading) {
      setSettings(data);
    }
  }, [isLoading, data]);

  const handleUpdateSettings = async (newSettings: SettingsRequestModel) => {
    setSettings({
      ...settings,
      ...(newSettings as SettingsResponseModel),
    });
    await updateSettings.mutateAsync(newSettings);
  };

  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        height: '100%',
        padding: 4,
      }}>
      <View style={{maxWidth: 400, width: '100%'}}>
        <ScrollView>
          <View style={styles.section}>
            <BottleTitle variant="headlineMedium">
              {getLocaleString('inventoryAlerts')}
            </BottleTitle>
            <View style={styles.row}>
              <Text variant="bodyLarge">
                {getLocaleString('pushNotifications')}
              </Text>
              <Switch
                disabled={updateSettings.isLoading}
                value={!!settings?.alertSettings?.pushNotifications}
                onValueChange={() => {
                  handleUpdateSettings({
                    alertSettings: {
                      emailAlerts: settings?.alertSettings?.emailAlerts,
                      pushNotifications:
                        !settings?.alertSettings?.pushNotifications,
                    },
                  });
                }}
              />
            </View>

            <View style={styles.row}>
              <Text variant="bodyLarge">{getLocaleString('emailAlerts')}</Text>
              <Switch
                disabled={updateSettings.isLoading}
                value={!!settings?.alertSettings?.emailAlerts}
                onValueChange={async () => {
                  handleUpdateSettings({
                    alertSettings: {
                      emailAlerts: !settings?.alertSettings?.emailAlerts,
                      pushNotifications:
                        settings?.alertSettings?.pushNotifications,
                    },
                  });
                }}
              />
            </View>
          </View>

          <View style={styles.section}>
            <BottleTitle variant="headlineMedium">
              {getLocaleString('lcboSpecialEvents')}
            </BottleTitle>
            <View style={styles.row}>
              <Text variant="bodyLarge">
                {getLocaleString('pushNotifications')}
              </Text>
              <Switch
                disabled={updateSettings.isLoading}
                value={!!settings?.lcboSpecialAlerts?.pushNotifications}
                onValueChange={() => {
                  handleUpdateSettings({
                    lcboSpecialAlerts: {
                      emailAlerts: settings?.lcboSpecialAlerts?.emailAlerts,
                      pushNotifications:
                        !settings?.lcboSpecialAlerts?.pushNotifications,
                    },
                  });
                }}
              />
            </View>

            <View style={styles.row}>
              <Text variant="bodyLarge">{getLocaleString('emailAlerts')}</Text>
              <Switch
                disabled={updateSettings.isLoading}
                value={!!settings?.lcboSpecialAlerts?.emailAlerts}
                onValueChange={async () => {
                  handleUpdateSettings({
                    lcboSpecialAlerts: {
                      emailAlerts: !settings?.lcboSpecialAlerts?.emailAlerts,
                      pushNotifications:
                        settings?.lcboSpecialAlerts?.pushNotifications,
                    },
                  });
                }}
              />
            </View>
          </View>
        </ScrollView>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    width: '100%',
    paddingTop: 8,
    paddingLeft: 4,
    paddingBottom: 8,
  },
  section: {
    marginTop: 20,
    marginBottom: 10,
    width: '100%',
  },
});

export default NotificationSettings;
