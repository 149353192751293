import {useState} from 'react';
import {Subheading, Title, useTheme} from 'react-native-paper';
import {StyleSheet} from 'react-native';
import {TextInput} from 'react-native-paper';
import {AuthStackParamList} from '../types';
import {getLocaleString} from '../../../utils/getLocaleString';
import BottleButton from '../../../components/BottleButton';
import { StackNavigationProp } from '@react-navigation/stack';
import AuthScreen from '../AuthScreen';
import { confirmResetPassword } from 'aws-amplify/auth';

type ResetPasswordProps = {
  email: string;
  navigation: StackNavigationProp<AuthStackParamList>;
};

const ResetPassword = ({email, navigation}: ResetPasswordProps) => {
  const [code, setCode] = useState('');
  const [error, setError] = useState('');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const {colors} = useTheme();

  const submitDisabled = !password || password !== confirmPassword || loading;

  const confirmPasswordError = !!(
    confirmPassword && confirmPassword !== password
  );

  const submit = async () => {
    setLoading(true);
    setError('');
    setPasswordError('');

    try {
      await confirmResetPassword({
        username: email,
        newPassword: password,
        confirmationCode: code
      })
      navigation.push('SignIn', {
        email: email,
        successMessage: getLocaleString('passwordResetSuccessful'),
      });
    } catch (e) {
      switch ((e as {name: string}).name) {
        case 'CodeMismatchException':
          setError(getLocaleString('invalidVerificationCode'));
          setCode('');
          break;
        case 'InvalidPasswordException':
          setError(getLocaleString('invalidPassword'));
          setPassword('');
          setConfirmPassword('');
          break;
        default:
          navigation.push('SignIn', {error: getLocaleString('sww')});
          break;
      }
    }
    setLoading(false);
  };

  return (
    <AuthScreen>
      <Title style={styles.text}>
        {getLocaleString('checkEmailForPasswordResetCode')}
      </Title>

      {!!error && (
        <Subheading style={{color: colors.error, textAlign: 'center'}}>
          {error}
        </Subheading>
      )}

      <TextInput
        autoComplete="off"
        dense={true}
        error={!!passwordError}
        label={getLocaleString('code')}
        mode="outlined"
        onChangeText={setCode}
        value={code}
        disabled={loading}
      />
      <TextInput
        autoComplete="off"
        dense={true}
        error={!!passwordError}
        label={getLocaleString('password')}
        mode="outlined"
        onChangeText={setPassword}
        secureTextEntry={true}
        value={password}
        disabled={loading}
      />
      <TextInput
        autoComplete="off"
        dense={true}
        label={getLocaleString('confirmPassword')}
        mode="outlined"
        onChangeText={setConfirmPassword}
        secureTextEntry={true}
        value={confirmPassword}
        disabled={loading}
        blurOnSubmit={true}
        onSubmitEditing={submit}
        returnKeyType="send"
        error={confirmPasswordError}
      />
      <BottleButton
        loading={loading}
        disabled={submitDisabled}
        mode="contained"
        onPress={submit}
        style={styles.button}>
        {getLocaleString('changePassword')}
      </BottleButton>
    </AuthScreen>
  );
};

const styles = StyleSheet.create({
  button: {
    marginTop: 10,
  },
  container: {
    display: 'flex',
  },
  text: {
    textAlign: 'center',
  },
});


export default ResetPassword;
