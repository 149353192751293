/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 * @export
 */
export const SubscriptionPlanEnum = {
    PremiumMonthly: 'premium_monthly',
    StandardMonthly: 'standard_monthly',
    LiteMonthly: 'lite_monthly'
} as const;
export type SubscriptionPlanEnum = typeof SubscriptionPlanEnum[keyof typeof SubscriptionPlanEnum];


export function SubscriptionPlanEnumFromJSON(json: any): SubscriptionPlanEnum {
    return SubscriptionPlanEnumFromJSONTyped(json, false);
}

export function SubscriptionPlanEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubscriptionPlanEnum {
    return json as SubscriptionPlanEnum;
}

export function SubscriptionPlanEnumToJSON(value?: SubscriptionPlanEnum | null): any {
    return value as any;
}

