/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SubscriptionCheckoutResponseModel
 */
export interface SubscriptionCheckoutResponseModel {
    /**
     * 
     * @type {string}
     * @memberof SubscriptionCheckoutResponseModel
     */
    checkoutSessionId: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionCheckoutResponseModel
     */
    checkoutSessionUrl: string;
}

/**
 * Check if a given object implements the SubscriptionCheckoutResponseModel interface.
 */
export function instanceOfSubscriptionCheckoutResponseModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "checkoutSessionId" in value;
    isInstance = isInstance && "checkoutSessionUrl" in value;

    return isInstance;
}

export function SubscriptionCheckoutResponseModelFromJSON(json: any): SubscriptionCheckoutResponseModel {
    return SubscriptionCheckoutResponseModelFromJSONTyped(json, false);
}

export function SubscriptionCheckoutResponseModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubscriptionCheckoutResponseModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'checkoutSessionId': json['checkoutSessionId'],
        'checkoutSessionUrl': json['checkoutSessionUrl'],
    };
}

export function SubscriptionCheckoutResponseModelToJSON(value?: SubscriptionCheckoutResponseModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'checkoutSessionId': value.checkoutSessionId,
        'checkoutSessionUrl': value.checkoutSessionUrl,
    };
}

