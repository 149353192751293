import { StackHeaderProps } from "@react-navigation/stack";
import { Image, Linking, StyleSheet, View } from "react-native";
import { Button, Divider, IconButton, Menu, Text } from "react-native-paper";
import { getLocaleString } from "../../../utils/getLocaleString";
import Keyword from "../../../components/Keyword";
import { useState } from "react";
import BottleMenu from "./BottleMenu";
import { PRIVACY_POLICY_URL, TERMS_OF_USE_URL } from "../../../constants";
import { useGetSettings } from "../../../api/settings";
import { useAppTheme } from "../../../theme";
import { signOutUser } from "../../../utils/user";

const BackRoutes = [
  'DangerZone',
  'Settings',
  'LocationSettings',
  'NotificationSettings',
  'SecuritySettings',
  'Search',
  'WatchdogInventory',
];

const BottleBar = (props: StackHeaderProps) => {
  const theme = useAppTheme();
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const {data: settingsData} = useGetSettings();
  
  const goBack = () => {
    if (props.back) {
      props.navigation.goBack();
      return;
    }
    props.navigation.push('Watchdogs');
  };

  const goSettings = () => {
    props.navigation.push('Settings');
    setIsMenuVisible(false);
  };

  return (
    <View style={styles.root}>
      <View style={styles.top}>
        {BackRoutes.includes(props.route.name) ? (
          <View style={styles.bottom}>
            <IconButton
              size={25}
              style={styles.backButton}
              icon="arrow-left"
              onPress={goBack}
            />
            <Keyword variant="headlineSmall">
              {props.options.title ?? props.route.name}
            </Keyword>
          </View>
        ) : (
          <View style={styles.imageDiv}>
            <Image
              style={styles.image}
              source={require('../../../../assets/bottlehounds_text_logo-min.png')}
              accessibilityLabel={getLocaleString('bottlehounds')}
              resizeMode="contain"
            />
          </View>
        )}

        <View style={styles.right}>
          <BottleMenu
            isMenuVisible={isMenuVisible}
            setIsMenuVisible={setIsMenuVisible}>
            {settingsData?.email && (
              <View
                style={{
                  alignItems: 'center',
                  paddingTop: 8,
                }}>
                <Text variant="labelLarge" style={{fontWeight: 'bold'}}>
                  {settingsData?.email}
                </Text>
              </View>
            )}

            <View style={{alignItems: 'center'}}>
              <Button
                mode="text"
                textColor={theme.colors.primary}
                compact={true}
                onPress={signOutUser}>
                {getLocaleString('signOut')}
              </Button>
            </View>

            <Divider style={{marginTop: 4}} />

            <Menu.Item
              leadingIcon="tools"
              onPress={goSettings}
              title={getLocaleString('settings')}
            />

            <Menu.Item
              leadingIcon="incognito"
              onPress={() => Linking.openURL(PRIVACY_POLICY_URL)}
              title={getLocaleString('privacyPolicy')}
              style={{
                ...styles.menuItem,
                marginTop: 40,
              }}
            />

            <Menu.Item
              leadingIcon="script-text"
              onPress={() => Linking.openURL(TERMS_OF_USE_URL)}
              title={getLocaleString('termsOfUse')}
              style={styles.menuItem}
            />
          </BottleMenu>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    paddingBottom: 8,
    width: '100%',
    margin: 'auto',
    height: 36,
  },
  top: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    height: 32,
  },
  bottom: {
    display: 'flex',
    flexDirection: 'row',
  },
  center: {
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  right: {
    alignItems: 'flex-end',
  },
  image: {
    height: 15,
    width: 150,
  },
  imageDiv: {
    paddingLeft: 4,
  },
  backButton: {
    margin: 0,
    height: 'auto',
    width: 'auto',
    paddingRight: 4,
  },
  menuItem: {
    paddingLeft: 8,
    paddingRight: 4,
    height: 42,
    width: 280,
  },
});

export default BottleBar;
