import {BOTTLEHOUNDS_API_NAME} from './constants';
import {useQuery} from '@tanstack/react-query';
import { Place, PlaceAutocompleteResult } from '@googlemaps/google-maps-services-js';
import { GetPlacesMapsPlacesGetRequest } from './openapi';
import { get } from 'aws-amplify/api';

export const MAPS_API_PATH = '/maps/places';

export const autocompletePlace = async (request: GetPlacesMapsPlacesGetRequest) =>
  (await (
    await get({
      apiName: BOTTLEHOUNDS_API_NAME,
      path: MAPS_API_PATH,
      options: {
        queryParams: request as unknown as Record<string, string>,
      },
    }).response
  ).body.json()) as unknown as PlaceAutocompleteResult[];

export const getPlace = async (placeId: string) =>
  (await (
    await get({
      apiName: BOTTLEHOUNDS_API_NAME,
      path: `${MAPS_API_PATH}/${placeId}`,
    }).response
  ).body.json()) as unknown as Place;

export const useAutocompletePlace = (request: GetPlacesMapsPlacesGetRequest) =>
  useQuery({
    queryKey: [MAPS_API_PATH, request],
    queryFn: () => autocompletePlace(request),
    enabled: !!request.query,
  });

export const useGetPlace = (placeId: string) =>
  useQuery({
    queryKey: [MAPS_API_PATH, placeId],
    queryFn: () => getPlace(placeId),
    enabled: !!placeId,
  });
