import { Chip, Icon, Text } from "react-native-paper";
import { getStockStatus } from "../utils";
import { useAppTheme } from "../../../theme";
import { STOCK_STATUS_COLOR, STOCK_STATUS_ICON_SOURCE } from "../../../constants";
import { getLocaleString } from "../../../utils/getLocaleString";

interface StockChipProps {
  totalInventory: number;
}

export const StockChip = ({totalInventory}: StockChipProps) => {
  const stockStatus = getStockStatus(totalInventory);
  const theme = useAppTheme();

  return (
    <Chip
      avatar={
        <Icon
          color={STOCK_STATUS_COLOR[stockStatus]}
          size={24}
          source={STOCK_STATUS_ICON_SOURCE[stockStatus]}
        />
      }
      compact={true}
      mode="outlined"
      style={{
        borderColor: STOCK_STATUS_COLOR[stockStatus],
        backgroundColor: theme.colors.elevation.level0,
      }}>
      <Text variant="titleMedium" style={{color: 'white'}}>
        {getLocaleString(stockStatus)}
      </Text>
    </Chip>
  );
}

export default StockChip;