/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SubscriptionCreateResponseModel
 */
export interface SubscriptionCreateResponseModel {
    /**
     * 
     * @type {string}
     * @memberof SubscriptionCreateResponseModel
     */
    subscriptionId: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionCreateResponseModel
     */
    clientSecret: string;
}

/**
 * Check if a given object implements the SubscriptionCreateResponseModel interface.
 */
export function instanceOfSubscriptionCreateResponseModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "subscriptionId" in value;
    isInstance = isInstance && "clientSecret" in value;

    return isInstance;
}

export function SubscriptionCreateResponseModelFromJSON(json: any): SubscriptionCreateResponseModel {
    return SubscriptionCreateResponseModelFromJSONTyped(json, false);
}

export function SubscriptionCreateResponseModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubscriptionCreateResponseModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'subscriptionId': json['subscriptionId'],
        'clientSecret': json['clientSecret'],
    };
}

export function SubscriptionCreateResponseModelToJSON(value?: SubscriptionCreateResponseModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'subscriptionId': value.subscriptionId,
        'clientSecret': value.clientSecret,
    };
}

