import {get, patch} from 'aws-amplify/api';
import {BOTTLEHOUNDS_API_NAME} from './constants';
import {SettingsRequestModel, SettingsResponseModel} from './openapi';
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {filterRetry} from './retry';

export const SETTINGS_API_PATH = '/settings';

export const getSettings = async () =>
  await (
    await get({
      apiName: BOTTLEHOUNDS_API_NAME,
      path: SETTINGS_API_PATH,
    }).response
  ).body.json() as unknown as SettingsResponseModel;

export const updateSettings = async (request: SettingsRequestModel) =>
  await (
    await patch({
      apiName: BOTTLEHOUNDS_API_NAME,
      path: SETTINGS_API_PATH,
      options: {body: request},
    }).response
  ).body.json() as unknown as SettingsResponseModel;

export const useGetSettings = () =>
  useQuery<SettingsResponseModel>({
    queryKey: [SETTINGS_API_PATH],
    queryFn: getSettings,
    retry: filterRetry,
  });

export const useUpdateSettings = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateSettings,
    onSuccess: () =>
      queryClient.invalidateQueries({queryKey: [SETTINGS_API_PATH]}),
  });
};
