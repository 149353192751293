import {BOTTLEHOUNDS_API_NAME} from './constants';
import {
  ProductModel, ProductSearchResultsModel
} from './openapi';
import { useQuery } from '@tanstack/react-query';
import { GetProductsLcboProductsGetRequest } from './openapi/apis';
import { filterRetry } from './retry';
import { get } from 'aws-amplify/api';

export const LCBO_PRODUCTS_API_PATH = '/lcbo/products';


export const getProduct = async (productId: string) =>
  (await (
    await get({
      apiName: BOTTLEHOUNDS_API_NAME,
      path: `${LCBO_PRODUCTS_API_PATH}/${productId}`,
    }).response
  ).body.json()) as unknown as ProductModel;


export const searchProducts = async (request: GetProductsLcboProductsGetRequest) =>
  await (await get({
    apiName: BOTTLEHOUNDS_API_NAME,
    path: LCBO_PRODUCTS_API_PATH,
    options: {
      queryParams: request as unknown as Record<string, string>,
    },
  }).response).body.json() as unknown as ProductSearchResultsModel;

export const useGetProduct = (productId: string) =>
  useQuery({
    queryKey: [LCBO_PRODUCTS_API_PATH, productId],
    queryFn: () => getProduct(productId),
  });

export const useSearchProducts = (
  request: GetProductsLcboProductsGetRequest,
  enabled = false,
) =>
  useQuery({
    queryKey: [LCBO_PRODUCTS_API_PATH, request],
    queryFn: () => searchProducts(request),
    enabled,
    retry: filterRetry,
  });
