import { StyleSheet, View } from "react-native";
import BottleSearch from "../components/BottleSearch/BottleSearch";
import { useState } from "react";
import { getLocaleString } from "../utils/getLocaleString";
import { Text } from "react-native-paper";

const Search = () => {
  const [searchRan, setSearchRan] = useState(false);

  return (
    <View style={styles.root}>
      {!searchRan && (
        <View style={styles.heading}>
          {/* <BottleTitle style={styles.welcome} variant="displaySmall">
            {getLocaleString('welcomeToBottleHounds')}
          </BottleTitle> */}

          <Text style={styles.text} variant="headlineSmall">
            {getLocaleString('searchForTheBottleOfYourDreamsBelow')}
          </Text>
        </View>
      )}
      <View
        style={{
          width: '100%',
          maxWidth: 800,
          flex: 2,
          marginTop: 12,
          display: 'flex',
          flexDirection: 'row',
        }}>
        <BottleSearch onSearch={() => setSearchRan(true)} />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: '100%',
    overflow: 'visible',
    width: '100%',
  },
  welcome: {
    textAlign: 'center',
    marginBottom: 30,
  },
  heading: {
    justifyContent: 'center',
    marginBottom: 40,
    marginTop: 20,
  },
  searchHeading: {
    marginTop: 20,
    marginBottom: 30,
    justifyContent: 'center',
  },
  text: {
    textAlign: 'center',
  },
  justify: {
    textAlign: 'justify',
  },
});

export default Search;
