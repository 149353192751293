import {useEffect, useMemo, useState} from 'react';
import {Linking, RefreshControl, ScrollView, View} from 'react-native';
import {SubscriptionPlanEnum} from '../../api/openapi';
import PlanCard from './PlanCard';
import {Icon, Text, useTheme} from 'react-native-paper';
import {getLocaleString} from '../../utils/getLocaleString';
import BottleTitle from '../../components/BottleTitle';
import {
  SUBSCRIPTION_PATH,
  useCreateSubscriptionCheckoutSession,
  useGetSubscription,
  useGetSubscriptionPlans,
} from '../../api/subscription';
import {SETTINGS_API_PATH, useGetSettings} from '../../api/settings';
import {queryClient} from '../../api/client';
import {HomeStackScreenProps} from '../Home/types';
import BottleLoader from '../../components/BottleLoader';
import BottleButton from '../../components/BottleButton';
import BottleScreen from '../../components/BottleScreen';
import {isUserActive} from '../../utils/user';
import {IS_MOBILE, IS_WEB} from '../../constants';
import Purchases, {
  PurchasesOfferings,
  PurchasesPackage,
} from 'react-native-purchases';
import { SubscriptionPlan } from './types';

const Subscribe = ({navigation, route}: HomeStackScreenProps<'Subscribe'>) => {
  const theme = useTheme();
  const [selectedPlanKey, setSelectedPlanKey] =
    useState<SubscriptionPlanEnum>('standard_monthly');
  const [checkoutPending, setCheckoutPending] = useState(false);
  const [offerings, setOfferings] = useState<PurchasesOfferings>();

  const {isLoading: isSubscriptionLoading, data: subscriptionData} =
    useGetSubscription(IS_MOBILE);
  const {isLoading: isSubscriptionPlansLoading, data: subscriptionPlans} =
    useGetSubscriptionPlans(IS_WEB);
  const {isLoading: isSettingsLoading, data: settings} = useGetSettings();
  const createSubscriptionCheckoutSession =
    useCreateSubscriptionCheckoutSession();

  const isActive =
    settings?.subscription && isUserActive(settings?.subscription);

  const checkoutSuccess = route.params?.session === 'success';

  const loading =
    (IS_MOBILE && isSubscriptionLoading) ||
    (IS_WEB && isSubscriptionPlansLoading) ||
    isSettingsLoading ||
    checkoutPending ||
    checkoutSuccess;

  const features = (offerings?.current?.metadata?.features || {}) as {
    [key: string]: string[];
  };

  const plans: SubscriptionPlan[] = useMemo(() => {
    if (subscriptionPlans?.plans) {
      return subscriptionPlans.plans;
    }

    if (offerings?.current?.availablePackages) {
      return offerings?.current?.availablePackages.map(offering => ({
        plan: offering.identifier as SubscriptionPlanEnum,
        name: offering.product.title.replace(' (Bottle Hounds)', ''),
        description: offering.product.description,
        features: features[offering.identifier] || [],
        unitAmount: offering.product.price,
        priceString: offering.product.priceString,
      }));
    }

    return [];
  }, [offerings, subscriptionPlans]);

  const onPress = async () => {
    setCheckoutPending(true);

    if (IS_MOBILE) {
      await Purchases.logIn(subscriptionData?.customerId as string);
      const selectedPackage = offerings?.current?.availablePackages.find(
        offering => offering.identifier === selectedPlanKey
      );
      await Purchases.purchasePackage(
        selectedPackage as PurchasesPackage,
        null,
        (
          isActive &&
          subscriptionData?.productId &&
          subscriptionData.store === 'play_store'
          ? {oldProductIdentifier: subscriptionData.productId}
          : null
        ),
      );
      refresh();
      return;
    }

    const result = await createSubscriptionCheckoutSession.mutateAsync(
      selectedPlanKey,
    );
    Linking.openURL(result.checkoutSessionUrl);
    refresh();
  };

  const invalidateCaches = () => {
    queryClient.invalidateQueries({queryKey: [SUBSCRIPTION_PATH]});
    queryClient.invalidateQueries({queryKey: [SETTINGS_API_PATH]});
  };

  const refresh = () => {
    if (!isActive) {
      setTimeout(refresh, 1500);
    }
    invalidateCaches();
  }

  const getOfferings = async () => {
    const offerings = await Purchases.getOfferings();
    if (
      offerings.current !== null &&
      offerings.current.availablePackages.length !== 0
    ) {
      setOfferings(offerings);
    } 
  };

  useEffect(() => {
    if (subscriptionData?.customerId) {
      getOfferings().catch((e) => console.error(e));
    }
  }, [subscriptionData]);

  useEffect(() => {
    invalidateCaches();
  }, []);

  useEffect(() => {
    if (isActive) {
      navigation.push('Watchdogs');
    }
  }, [checkoutPending, settings]);

  return (
    <BottleScreen>
      {loading && (
        <ScrollView
          contentContainerStyle={{
            height: '100%',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          refreshControl={
            <RefreshControl
              refreshing={isSettingsLoading}
              onRefresh={refresh}
            />
          }>
          <BottleLoader size="large" />
        </ScrollView>
      )}
      {!loading && !!plans && (
        <View style={{height: '100%', width: '100%'}}>
          <View style={{marginBottom: 4, marginTop: 15, alignItems: 'center'}}>
            <BottleTitle variant="displaySmall" style={{textAlign: 'center'}}>
              {getLocaleString('chooseYourPlan')}
            </BottleTitle>
          </View>

          <ScrollView style={{flex: 1, width: '100%'}}>
            <View
              style={{
                width: '100%',
                maxWidth: 600,
                padding: 4,
                margin: 'auto',
              }}>
              <View
                style={{
                  width: '100%',
                  marginBottom: 15,
                }}>
                <View
                  style={{paddingLeft: 10, paddingRight: 5, paddingBottom: 15}}>
                  <Text
                    variant="titleLarge"
                    style={{marginTop: 12, marginBottom: 4}}>
                    {getLocaleString('eachPlanIncludes')}
                  </Text>
                  {!subscriptionData?.trialled && (
                    <View style={{paddingLeft: 4, flexDirection: 'row'}}>
                      <Icon
                        source="check"
                        size={25}
                        color={theme.colors.primary}
                      />
                      <Text
                        style={{marginLeft: 5, flex: 1, flexWrap: 'wrap'}}
                        variant="titleMedium">
                        {getLocaleString('thirtyDayFreeTrial')}
                      </Text>
                    </View>
                  )}
                  <View style={{paddingLeft: 4, flexDirection: 'row'}}>
                    <Icon
                      source="check"
                      size={25}
                      color={theme.colors.primary}
                    />
                    <Text
                      style={{marginLeft: 5, flex: 1, flexWrap: 'wrap'}}
                      variant="titleMedium">
                      {getLocaleString('frequentInventoryChecks')}
                    </Text>
                  </View>
                  <View style={{paddingLeft: 4, flexDirection: 'row'}}>
                    <Icon
                      source="check"
                      size={25}
                      color={theme.colors.primary}
                    />
                    <Text
                      style={{marginLeft: 5, flex: 1, flexWrap: 'wrap'}}
                      variant="titleMedium">
                      {getLocaleString('realTimeAlerting')}
                    </Text>
                  </View>
                </View>
                {plans.map(plan => (
                  <PlanCard
                    key={plan.plan}
                    plan={plan}
                    selected={selectedPlanKey === plan.plan}
                    setPlan={setSelectedPlanKey}
                  />
                ))}
              </View>
            </View>
          </ScrollView>
          <View style={{marginTop: 10}}>
            <BottleButton
              mode="contained"
              disabled={!selectedPlanKey}
              onPress={onPress}>
              {getLocaleString(
                subscriptionData?.trialled ? 'subscribe' : 'startFreeTrial',
              )}
            </BottleButton>
          </View>
        </View>
      )}
    </BottleScreen>
  );
};

export default Subscribe;
