import { Card, RadioButton, Text } from "react-native-paper";
import { SubscriptionPlanEnum } from "../../api/openapi";
import { Dispatch, SetStateAction } from "react";
import { View } from "react-native";
import Keyword from "../../components/Keyword";
import { getLocaleString } from "../../utils/getLocaleString";
import PlanChip from "./PlanChip";
import { useAppTheme } from "../../theme";
import { SubscriptionPlan } from "./types";

interface PlanCardProps {
  plan: SubscriptionPlan;
  selected: boolean;
  setPlan: Dispatch<SetStateAction<any>>;
}

const PlanCard = ({plan, selected, setPlan}: PlanCardProps) => {
  const theme = useAppTheme();
  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        marginTop: plan.plan === 'lite_monthly' ? 10 : 0,
        marginBottom: 15,
      }}>
      {plan.plan === 'premium_monthly' && (
        <PlanChip icon="crown" text={getLocaleString('bestValue')} />
      )}
      {plan.plan === 'standard_monthly' && (
        <PlanChip icon="heart" text={getLocaleString('mostPopular')} />
      )}
      <Card
        onPress={() => setPlan(plan.plan)}
        style={{
          ...{cursor: 'pointer'},
          backgroundColor: theme.colors.primary5,
          padding: 0,
          margin: 0,
          width: '100%',
          borderWidth: 2,
          borderColor: selected
            ? theme.colors.primary
            : theme.colors.elevation.level1,
        }}>
        <Card.Content style={{padding: 0, margin: 0}}>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
            }}>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                flexShrink: 1,
                paddingRight: 0,
                paddingBottom: 4,
                paddingTop: 4,
                paddingLeft: 0,
              }}>
              <RadioButton.Item
                label=""
                value="plan"
                style={{
                  padding: 0,
                  margin: 0,
                  marginLeft: 0,
                  paddingRight: 0,
                  paddingLeft: 0,
                }}
                status={selected ? 'checked' : 'unchecked'}
                onPress={() => setPlan(plan.plan)}
              />
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  flexShrink: 1,
                }}>
                <Text
                  variant="titleLarge"
                  style={{paddingBottom: 4, fontWeight: 'bold'}}>
                  {plan.name}
                </Text>

                <Keyword variant="titleMedium" style={{fontWeight: 'bold'}}>
                  {plan.features[0]}
                </Keyword>

                <Text variant="titleMedium" style={{paddingTop: 4}}>
                  {plan.description}
                </Text>
              </View>
            </View>

            <View
              style={{
                justifyContent: 'center',
              }}>
              <View style={{marginRight: 10, alignItems: 'flex-end'}}>
                <Keyword variant="headlineLarge" style={{fontWeight: 'bold'}}>
                  { plan.priceString?.replace(".00", "") || `$${(plan.unitAmount / 100)}`}
                </Keyword>
                <Text variant="labelLarge" style={{fontStyle: 'italic'}}>
                  {getLocaleString('perMonth')}
                </Text>
              </View>
            </View>
          </View>
        </Card.Content>
      </Card>
    </View>
  );
}

export default PlanCard;
