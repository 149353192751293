import { Image, StyleSheet } from "react-native"
import MapView, { Circle, Marker, PROVIDER_GOOGLE } from "react-native-maps";
import { mapStyle } from "./mapstyle";
import { MapProps } from "./types";
import { RadiusDeltas } from "./constants";
import { getLocaleString } from "../../../utils/getLocaleString";
import { useAppTheme } from "../../../theme";

const MobileMap = ({radius, region, onMapLoaded}: MapProps) => {
  const mapRegion = {
    ...region,
    ...{
      latitudeDelta: RadiusDeltas[radius],
      longitudeDelta: RadiusDeltas[radius],
    },
  };
  const theme = useAppTheme();
  return (
    <MapView
      onMapLoaded={onMapLoaded}
      style={styles.map}
      provider={PROVIDER_GOOGLE}
      region={mapRegion}
      customMapStyle={mapStyle}
      scrollEnabled={false}>
      {radius !== 0 && (
        <Circle
          center={region}
          radius={radius}
          strokeWidth={2}
          strokeColor={theme.colors.primary}
          fillColor={theme.colors.primary + '30'}
        />
      )}

      <Marker coordinate={region}>
        <Image
          style={styles.marker}
          source={require('../../../../assets/bottlehounds_logo-min.png')}
          accessibilityLabel={getLocaleString('bottlehoundsLogo')}
          resizeMode="contain"
        />
      </Marker>
    </MapView>
  );
};

const styles = StyleSheet.create({
  map: {
    ...StyleSheet.absoluteFillObject,
  },
  marker: {
    height: 40,
  },
});

export default MobileMap
