import { ListRenderItem, ScrollView, StyleSheet, View } from "react-native";
import {
  Divider, RadioButton, Text, TextInput
} from "react-native-paper";
import { ReactNode, useEffect, useRef, useState } from "react";
import {
  PlaceAutocompleteResult,
} from "@googlemaps/google-maps-services-js";
import { FlatList } from "react-native-gesture-handler";
import Map from "./Map";
import { useAutocompletePlace, useGetPlace } from "../../api/maps";
import { useGetSettings, useUpdateSettings } from "../../api/settings";
import { getLocaleString } from "../../utils/getLocaleString";
import Searchbar from "../../design-system/SearchBar";
import { RadiusOptions } from "./constants";
import { RadiusOption } from "./types";
import BottleLoader from "../BottleLoader";
import BottleButton from "../BottleButton";
import { useAppTheme } from "../../theme";
import BottleListItem from "../BottleListItem";
import MapView, { PROVIDER_GOOGLE } from "react-native-maps";

interface BottleLocationProps {
  onSave?: () => void;
  bottomText?: ReactNode;
  onPlaceSelected?: (newPlaceId: string) => void;
}

const BottleLocation = (
  {bottomText, onPlaceSelected, onSave}: BottleLocationProps
) => {
  const theme = useAppTheme();

  const [loading, setLoading] = useState<boolean>(false);
  const [input, setInput] = useState<string>('');
  const [autoCompleteInput, setAutoCompleteInput] = useState<string>('');
  const [placeId, setPlaceId] = useState<string>('');
  const [radius, setRadius] = useState<RadiusOption>(25000);
  const [inputTimeout, setInputTimeout] = useState<NodeJS.Timeout>(
    setTimeout(() => {}),
  );
  const [mapLoaded, setMapLoaded] = useState(false);

  const suggestions = useAutocompletePlace({query: autoCompleteInput});
  const place = useGetPlace(placeId);
  const updateSettings = useUpdateSettings();
  const {data, isLoading} = useGetSettings();

  const region = place?.data?.geometry
    ? {
        latitude: place.data.geometry.location.lat,
        longitude: place.data.geometry.location.lng,
      }
    : null;
    
  const scrollView = useRef<ScrollView>(null);

  const selectPlace = (newPlaceId: string) => {
    setPlaceId(newPlaceId);
    if (onPlaceSelected) {
      onPlaceSelected(newPlaceId);
    }
  }

  const handleInput = (value: string) => {
    clearTimeout(inputTimeout);
    setInput(value);
    setInputTimeout(
      setTimeout(() => {
        setAutoCompleteInput(value);
      }, 200),
    );
  };

  const save = async () => {
    if (region) {
      setLoading(true);

      await updateSettings.mutateAsync({
        locationRadius: {
          placeId: placeId,
          ...region,
          radius,
          address: place.data?.formatted_address,
        },
      });

      if (onSave) {
        onSave();
        return;
      }

      setLoading(false);
    }
  };

  const renderItem: ListRenderItem<PlaceAutocompleteResult> = ({item}) => (
    <BottleListItem
      key={item.place_id}
      title={item.structured_formatting.main_text}
      description={item.structured_formatting.secondary_text}
      onPress={() => selectPlace(item.place_id)}
    />
  );

  useEffect(() => {
    if (data?.locationRadius?.placeId) {
      setPlaceId(data?.locationRadius?.placeId);
    }
    setRadius((data?.locationRadius?.radius ?? radius) as RadiusOption);
  }, [data, isLoading])

  return (
    <View style={styles.root}>
      {!placeId && !isLoading && (
        <View style={{flex: 1, minHeight: 400}}>
          <Searchbar
            autoFocus={true}
            autoCorrect={false}
            autoComplete="off"
            placeholder={getLocaleString('exampleToronto')}
            onChangeText={handleInput}
            value={input}
            clearIcon="close"
            onClearIconPress={() => handleInput('')}
            mode="bar"
            inputStyle={{
              color: theme.colors.onSurface,
              fontSize: 18,
            }}
            placeholderTextColor="#eeeeee"
          />

          {suggestions.isFetching && (
            <View style={{margin: 'auto'}}>
              <BottleLoader size="large" />
            </View>
          )}

          {!suggestions.data?.length &&
            !suggestions.isFetching &&
            suggestions.isSuccess && (
              <View style={{margin: 'auto'}}>
                <Text style={{textAlign: 'center'}} variant="titleLarge">
                  {getLocaleString('noResults')}
                </Text>
                <Text
                  style={{textAlign: 'center', paddingTop: 8}}
                  variant="titleMedium">
                  {getLocaleString('pleaseAlterYourSearch')}
                </Text>
              </View>
            )}

          {!suggestions.isFetching && !!suggestions.data?.length && (
            <FlatList
              data={suggestions.data ?? []}
              renderItem={renderItem}
              keyExtractor={(item: PlaceAutocompleteResult) => item.place_id}
            />
          )}
        </View>
      )}

      {!!place?.data?.formatted_address && (
        <TextInput
          left={
            <TextInput.Icon
              icon="circle-edit-outline"
              color={theme.colors.primary}
            />
          }
          style={styles.selectedLocation}
          autoFocus={false}
          dense={true}
          mode="outlined"
          onFocus={() => selectPlace('')}
          value={place?.data?.formatted_address}
        />
      )}

      {!!placeId && !!region && (
        <>
          <View
            style={styles.mapContainer}>
            {!mapLoaded && <BottleLoader size="large" />}
            <Map
              radius={radius}
              region={region}
              onMapLoaded={() => setMapLoaded(true)}
            />
          </View>

          <ScrollView style={styles.radiusOptions} ref={scrollView}>
            <RadioButton.Group
              onValueChange={(value: string) =>
                setRadius(Number(value) as RadiusOption)
              }
              value={String(radius)}>
              {RadiusOptions.map(radiusOption => {
                const label = radiusOption
                  ? radiusOption / 1000 + ' KM'
                  : getLocaleString('noLimit');
                return (
                  <View
                    key={radiusOption}
                    onLayout={event => {
                      if (radius === radiusOption) {
                        scrollView.current?.scrollTo({
                          y: event.nativeEvent.layout.y,
                        });
                      }
                    }}>
                    <RadioButton.Item
                      style={{
                        paddingTop: 4,
                        paddingBottom: 4,
                      }}
                      label={label}
                      value={String(radiusOption)}
                      color={theme.colors.primary}
                      labelVariant="bodyMedium"
                    />
                    <Divider />
                  </View>
                );
              })}
            </RadioButton.Group>
          </ScrollView>

          {!!bottomText && (
            <Text variant="bodyLarge" style={styles.bottomText}>
              {bottomText}
            </Text>
          )}

          <View style={styles.footer}>
            <BottleButton
              disabled={!region || loading}
              loading={loading}
              mode="contained"
              onPress={save}>
              {getLocaleString('save')}
            </BottleButton>
          </View>
        </>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    height: '100%',
    width: '100%',
  },
  mapContainer: {
    height: "40%",
    width: "100%",
    justifyContent: "flex-end",
    alignItems: "center",
    maxHeight: 350,
  },
  marker: {
    height: 40,
  },
  radiusOptions: {
    marginTop: 8,
  },
  selectedLocation: {
    textAlign: 'auto',
    marginBottom: 4,
  },
  bottomText: {
    textAlign: 'center',
    marginTop: 8,
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'center',
    marginTop: 8,
  },
});

export default BottleLocation;
