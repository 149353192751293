import { ScrollView, View } from "react-native";
import { Button, Icon, List, Text } from "react-native-paper";
import { getLocaleString } from "../../utils/getLocaleString";
import { HomeStackScreenProps } from "../Home/types";
import { useEffect, useState } from "react";
import BottleLoader from "../../components/BottleLoader";
import { getCurrentUser } from "aws-amplify/auth";
import { useGetSettings } from "../../api/settings";
import { getUserOnboardingStep, signOutUser } from "../../utils/user";
import { SettingsResponseModel } from "../../api/openapi";
import { useAppTheme } from "../../theme";
import { IS_MOBILE } from "../../constants";


const Settings = ({navigation}: HomeStackScreenProps<'Settings'>) => {
  const theme = useAppTheme();

  const [loading, setLoading] = useState<boolean>(true);
  const [isExternalUser, setIsExternalUser] = useState<boolean>(false);
  const {isLoading: isSettingsLoading, data: settingsData} = useGetSettings();

  useEffect(() => {
    getCurrentUser().then(({username}) => {
      if (username.includes('_')) {
        setIsExternalUser(true);
      }
      setLoading(false);
    });
  }, []);

  return loading || isSettingsLoading ? (
    <View
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 4,
        height: '100%',
      }}>
      <BottleLoader size="large" />
    </View>
  ) : (
    <View
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        padding: 4,
        height: '100%',
      }}>
      <View
        style={{
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <View
          style={{flexDirection: 'row', alignItems: 'center', paddingTop: 8}}>
          <View style={{paddingRight: 4}}>
            <Icon source="account-circle" size={36} />
          </View>
          <Text variant="labelLarge">{getLocaleString('signedInAs')} </Text>
          <Text
            variant="labelLarge"
            numberOfLines={1}
            style={{fontWeight: 'bold', flexWrap: 'nowrap'}}>
            {settingsData?.email}
          </Text>
        </View>
        <Button
          mode="text"
          textColor={theme.colors.primary}
          compact={true}
          onPress={signOutUser}
          style={{margin: 0, padding: 0}}>
          {getLocaleString('signOut')}
        </Button>
      </View>

      <ScrollView
        style={{width: '100%'}}
        contentContainerStyle={{alignItems: 'center', flex: 1}}>
        <View style={{height: '100%', maxWidth: 600, width: '100%'}}>
          {!getUserOnboardingStep(settingsData as SettingsResponseModel) && (
            <List.Section>
              <List.Subheader>{getLocaleString('preferences')}</List.Subheader>
              <List.Item
                title={getLocaleString('location')}
                description={getLocaleString('locationDescription')}
                left={props => (
                  <List.Icon {...props} icon="map-marker-radius" />
                )}
                onPress={() => navigation.push('LocationSettings')}
              />
              <List.Item
                title={getLocaleString('notifications')}
                description={getLocaleString('notificationsDescription')}
                left={props => <List.Icon {...props} icon="bell" />}
                onPress={() => navigation.push('NotificationSettings')}
              />
            </List.Section>
          )}

          <List.Section>
            <List.Subheader>{getLocaleString('account')}</List.Subheader>

            {!isExternalUser && (
              <List.Item
                title={getLocaleString('security')}
                description={getLocaleString('securityDescription')}
                left={props => <List.Icon {...props} icon="lock" />}
                onPress={() => navigation.push('SecuritySettings')}
              />
            )}

            <List.Item
              title={getLocaleString('subscription')}
              description={getLocaleString('subscriptionDescription')}
              onPress={() => navigation.push('SubscriptionSettings')}
              left={props => <List.Icon {...props} icon="credit-card" />}
            />

            <List.Item
              title={getLocaleString('dangerZone')}
              description={getLocaleString('dangerZoneDescription')}
              onPress={() => navigation.push('DangerZone')}
              left={props => <List.Icon {...props} icon="skull-crossbones" />}
              style={{marginTop: 25}}
            />
          </List.Section>
        </View>
      </ScrollView>
      {IS_MOBILE && (
        <View
          style={{
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Text
            variant="bodySmall"
            style={{
              textAlign: 'center',
              paddingTop: 4,
              paddingBottom: 8,
            }}>
            © Copyright 2024 - {new Date().getFullYear()} Bottle Hounds Inc.
          </Text>
          <Text variant="bodySmall">All rights reserved.</Text>
        </View>
      )}
    </View>
  );
};


export default Settings;
