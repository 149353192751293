import { ReactNode } from 'react';
import { View, StyleSheet } from 'react-native';
import { Card, useTheme, Text } from 'react-native-paper';
import { ProductModel } from '../api/openapi';
import BottleTitle from './BottleTitle';

interface BottleCardProps {
  action?: ReactNode;
  bottomAction?: ReactNode;
  product: ProductModel;
};
  
export const BottleCard = ({ action, bottomAction, product }: BottleCardProps) => {
  const theme = useTheme();
  return (
    <Card
      style={{
        ...styles.card,
        ...{
          borderLeftColor: theme.colors.primary,
          borderLeftWidth: 2,
        },
      }}
      elevation={1}>
      <View style={styles.cardTitle}>
        <View style={{flexDirection: 'column', flex: 1}}>
          <BottleTitle variant="titleLarge" numberOfLines={3}>
            {product.name}
          </BottleTitle>
          <Text variant="bodyLarge" style={{marginTop: 4}}>
            {product.region}
            {product.region ? ', ' : ''}
            {product.country}
          </Text>
        </View>
      </View>
      <Card.Cover
        source={{uri: product.imageURL}}
        accessibilityLabel={product.name}
        resizeMode="contain"
        style={styles.image}
      />
      <Card.Content style={styles.cardContent}>
        <View style={styles.details}>
          <Text
            variant="titleLarge"
            style={{fontFamily: 'monospace', fontWeight: 'bold'}}>
            {'$' + product.price.toFixed(2)}
          </Text>
          <View style={styles.detailsRight}>
            <Text variant="bodyMedium">
              {product.quantity && product.quantity > 1
                ? product.quantity + ' x '
                : ''}
              {product.volume ? product.volume + ' ml ' : ''}
              {product._package}
            </Text>
          </View>
        </View>
      </Card.Content>
      {bottomAction && (
        <Card.Actions style={styles.cardActions}>{bottomAction}</Card.Actions>
      )}
    </Card>
  );
};

const styles = StyleSheet.create({
  card: {
    marginBottom: 10,
    marginTop: 10,
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
    maxWidth: 500,
    borderLeftWidth: 1,
  },
  cardActions: {
    margin: 0,
    padding: 0,
  },
  cardContent: {
    paddingBottom: 0,
  },
  cardTitle: {
    padding: 0,
    paddingLeft: 8,
    paddingTop: 8,
    paddingBottom: 8,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  image: {
    backgroundColor: 'white',
    height: 130,
  },
  details: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    marginTop: 10,
    marginBottom: 10,
  },
  detailsRight: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    marginLeft: 15,
    flex: 1,
  },
});
