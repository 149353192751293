/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SubscriptionPlanEnum } from './SubscriptionPlanEnum';
import {
    SubscriptionPlanEnumFromJSON,
    SubscriptionPlanEnumFromJSONTyped,
    SubscriptionPlanEnumToJSON,
} from './SubscriptionPlanEnum';

/**
 * 
 * @export
 * @interface SubscriptionPlanModel
 */
export interface SubscriptionPlanModel {
    /**
     * 
     * @type {SubscriptionPlanEnum}
     * @memberof SubscriptionPlanModel
     */
    plan: SubscriptionPlanEnum;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionPlanModel
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionPlanModel
     */
    description: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SubscriptionPlanModel
     */
    features: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionPlanModel
     */
    unitAmount: number;
}

/**
 * Check if a given object implements the SubscriptionPlanModel interface.
 */
export function instanceOfSubscriptionPlanModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "plan" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "features" in value;
    isInstance = isInstance && "unitAmount" in value;

    return isInstance;
}

export function SubscriptionPlanModelFromJSON(json: any): SubscriptionPlanModel {
    return SubscriptionPlanModelFromJSONTyped(json, false);
}

export function SubscriptionPlanModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubscriptionPlanModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'plan': SubscriptionPlanEnumFromJSON(json['plan']),
        'name': json['name'],
        'description': json['description'],
        'features': json['features'],
        'unitAmount': json['unitAmount'],
    };
}

export function SubscriptionPlanModelToJSON(value?: SubscriptionPlanModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'plan': SubscriptionPlanEnumToJSON(value.plan),
        'name': value.name,
        'description': value.description,
        'features': value.features,
        'unitAmount': value.unitAmount,
    };
}

