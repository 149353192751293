export default {
  account: 'Account',
  active: 'Active',
  address: 'Address',
  addWatchdog: 'Add Watchdog',
  allocatedOffers: 'Allocated Offers',
  allocatedOffersDescription: 'Limited quanity offers of rare bottles, randomly assigned to interested buyers.',
  aWatchdogIsAResourceDedicated: 'A ^{watchdog}^ is a resource dedicated to tracking down your bottle. When you see your bottle below, ^{track}^ it!',
  back: 'Back',
  bestValue: 'Best Value',
  bottlehounds: 'Bottle Hounds',
  bottlehoundsLogo: 'Bottle Hounds Logo',
  bottleSearchExample: 'i.e. "Eagle Rare"',
  buyAtLcbo: 'Buy at LCBO',
  bySigningUp: 'By signing up, I agree to ^{tac}^ & ^{privacy}^',
  cancel: 'Cancel',
  centerYourSearch: 'Center your Search',
  changePassword: 'Change Password',
  checkEmailForVerificationCode: 'A verification code has been sent to your email. Please enter it below.',
  checkEmailForPasswordResetCode: 'A verification code has been sent to your email. Please enter it below and set your desired password.',
  chooseYourPlan: 'Choose your Plan',
  code: 'Code',
  confirmPassword: 'Confirm Password',
  continue: 'Continue',
  defaultLocation: 'Default Location',
  defaultRadius: 'Default Radius',
  delete: 'Delete',
  deleteFromList: 'Delete ^{watchdog}^ from your list?',
  eachPlanIncludes: 'Each plan includes:',
  email: 'Email',
  emailAlerts: 'Email Alerts',
  emailAlertsDescription: 'Required for Web App users.',
  enter: 'Enter',
  enterResetEmail: 'Please enter the email associated with your account.',
  enterWatchdog: 'Enter ^{watchdog}^',
  realTimeAlerting: 'Real-time, geo-relevant inventory alerts',
  exampleToronto: 'i.e. "1 Nice St" or "Toronto"',
  firstName: 'First Name',
  frequentInventoryChecks: 'Frequent bottle checks, every 4-7 minutes',
  forgotPassword: 'Forgot Password',
  forgotYourPassword: 'Forgot your password?',
  howWouldYouLikeToReceiveAlerts: 'How would you like to receive alerts?',
  incorrectUsernameOrPassword: 'Incorrect username or password.',
  inStock: 'In Stock',
  inStockDescription: 'Desired bottle is available.',
  invalidEmail: 'Email must be a valid email address.',
  invalidPassword: 'Password must be at least 12 characters, without spaces, and must include at least one of each: uppercase letter, lowercase letter, number, special character',
  invalidVerificationCode: 'Invalid verification code provided, please try again.',
  inventory: 'Inventory',
  inventoryAlerts: 'Inventory Alerts',
  keepAlertsRelativeToYou: 'Keep alerts relative to you or any place you wish. Be as sepecific as an address, or as vaugue as a city.',
  lastName: 'Last Name',
  lcboSpecialEvents: 'LCBO Special Alerts',
  letsFindYouTheBottleOfYourDreams: "Let's find you the bottle of your dreams.",
  lite_monthly: 'Lite',
  location: 'Location',
  locationDescription: 'Default settings for search location and radius.',
  locationSettings: 'Location Settings',
  lowStock: 'Low Stock',
  lowStockDescription: 'Desired bottle has limited availability.',
  mostPopular: 'Most Popular',
  next: 'Next',
  no: 'No',
  noLimit: 'No Limit',
  noResults: 'No Results Found',
  noStock: 'No Stock',
  noStockDescription: 'Desired bottle is unavailable.',
  notifications: 'Notifications',
  notificationsDescription: 'Default push notification and email alert settings.',
  notificationSettings: 'Notification Settings',
  oldPassword: 'Old Password',
  onboardingSetup: "Let's find you the bottle of your dreams. First, we'll need to set a few things up.",
  onlineInventory: 'Online Inventory',
  password: 'Password',
  passwordResetCode: 'Password Reset Code',
  passwordResetSuccessful: 'Password reset successful.',
  perMonth: 'per month',
  plans: {
    lite_monthly: {
      name: 'Lite',
      description: 'An entry-level option for the casual bottle seeker.'
    },
    premium_monthly: {
      name: 'Premium',
      description: 'Ideal for aficionados and industry professionals.'
    },
    standard_monthly: {
      name: 'Standard',
      description: 'A great starting point for most users.'
    }
  },
  pleaseEnterAFewDetails: 'Please enter a few details to begin.',
  pleaseChangeYourSearch: 'Please change your search and try again.',
  pleaseReviewYourSubscription: 'Please review your Subscription.',
  preferences: 'Preferences',
  premium_monthly: 'Premium',
  privacyPolicy: 'Privacy Policy',
  pushNotifications: 'Push Notifications',
  pushNotificationsDescription: 'Recommended for Mobile App users.',
  resetPassword: 'Reset Password',
  results: 'results',
  save: 'Save',
  search: 'Search',
  searchBottles: 'Search bottles',
  searchForAPlaceBelow: 'Search for a place below. You can be as vague as a city or as specific as an address.',
  searchForTheBottleOfYourDreamsBelow: 'Search for the bottle of your dreams below.',
  searchCenter: 'Search Center',
  searchRadius: 'Search Radius',
  security: 'Security',
  securityDescription: 'Account password settings.',
  securitySettings: 'Security Settings',
  selectYourAlerts: 'Select your Alerts',
  sendEmail: 'Send Email',
  settings: 'Settings',
  setYourBounds: 'Set your Bounds',
  setYourPreferences: 'Set your preferences:',
  signIn: 'Sign In',
  signInWithApple: 'Sign In with Apple',
  signInWithFacebook: 'Sign In with Facebook',
  signInWithGoogle: 'Sign In with Google',
  signOut: 'Sign Out',
  signUp: 'Sign Up',
  specialOffers: 'Special Offers',
  specialOffersDescription: 'Exclusive access to small-lot, rare, and artisanal wines and spirits.',
  startFreeTrial: 'Start Free Trial',
  storeInventory: 'Store Inventory',
  subscription: 'Subscription',
  subscriptionDescription: 'Subscription plan, payment method, etc.',
  subscriptionInactive: 'Subscription Inactive',
  swap: 'Swap',
  swapWatchdog: 'Swap ^{watchdog}^ for:',
  sww: 'Something went wrong. Please try again.',
  swwSignUp: 'Something went wrong. Please confirm your information below and try again.',
  termsOfUse: 'Terms of Use',
  theLCBOPeriodicallyPutsUp: 'The LCBO puts up allocation lotteries for rare bottles, as well as other special offers. We can alert you when this happens.',
  thirtyDayFreeTrial: '30 day free trial, cancel at any time',
  track: 'Track',
  userExists: 'A user with the given email already exists.',
  verifyEmail: 'Verify Email',
  viaEmail: 'Via Email',
  view: 'View',
  viewInventory: 'View Inventory',
  viewSubscription: 'View Subscription',
  watchdogs: 'Watchdogs',
  watchdogDefaults: 'Watchdog Defaults',
  weCanAlertYouLcbo: 'We can alert you on the following LCBO events:',
  welcome: 'Welcome.',
  welcomeToBottleHounds: 'Welcome to Bottle Hounds',
  whenYouFindWhatYoureLookingForAddIt: 'When you find what you\'re looking for, simply add it!',
  yes: 'Yes',
  youCanChangeYourPreferencesAtAnyTime: 'You can change your preferences at any time.',
  youHaveNMoreWatchdogsAvailable: 'You have ^{watchdogsAvailable}^ ^{watchdogs}^ available:',
  youMayBeAsVagueAsACity: 'You can be as vague as a city or as precise as an address.',
  youShouldBeRedirected: 'You should be redirected automatically, otherwise click the button below.',
  yourWatchdogWillNotifyYouOfTheFollowingEvents: 'Your ^{watchdog}^ will notify you of the following events:'
}
