/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProductModel
 */
export interface ProductModel {
    /**
     * 
     * @type {string}
     * @memberof ProductModel
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ProductModel
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ProductModel
     */
    _package?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductModel
     */
    quantity?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductModel
     */
    volume?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductModel
     */
    alcoholPercentage?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductModel
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductModel
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductModel
     */
    imageURL: string;
    /**
     * 
     * @type {number}
     * @memberof ProductModel
     */
    onlineInventory: number;
    /**
     * 
     * @type {number}
     * @memberof ProductModel
     */
    price: number;
    /**
     * 
     * @type {number}
     * @memberof ProductModel
     */
    promoPrice?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductModel
     */
    region?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductModel
     */
    uri: string;
}

/**
 * Check if a given object implements the ProductModel interface.
 */
export function instanceOfProductModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "imageURL" in value;
    isInstance = isInstance && "onlineInventory" in value;
    isInstance = isInstance && "price" in value;
    isInstance = isInstance && "uri" in value;

    return isInstance;
}

export function ProductModelFromJSON(json: any): ProductModel {
    return ProductModelFromJSONTyped(json, false);
}

export function ProductModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        '_package': !exists(json, 'package') ? undefined : json['package'],
        'quantity': !exists(json, 'quantity') ? undefined : json['quantity'],
        'volume': !exists(json, 'volume') ? undefined : json['volume'],
        'alcoholPercentage': !exists(json, 'alcoholPercentage') ? undefined : json['alcoholPercentage'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'imageURL': json['imageURL'],
        'onlineInventory': json['onlineInventory'],
        'price': json['price'],
        'promoPrice': !exists(json, 'promoPrice') ? undefined : json['promoPrice'],
        'region': !exists(json, 'region') ? undefined : json['region'],
        'uri': json['uri'],
    };
}

export function ProductModelToJSON(value?: ProductModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'package': value._package,
        'quantity': value.quantity,
        'volume': value.volume,
        'alcoholPercentage': value.alcoholPercentage,
        'country': value.country,
        'description': value.description,
        'imageURL': value.imageURL,
        'onlineInventory': value.onlineInventory,
        'price': value.price,
        'promoPrice': value.promoPrice,
        'region': value.region,
        'uri': value.uri,
    };
}

