/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LocationRadiusModel
 */
export interface LocationRadiusModel {
    /**
     * 
     * @type {string}
     * @memberof LocationRadiusModel
     */
    placeId: string;
    /**
     * 
     * @type {string}
     * @memberof LocationRadiusModel
     */
    address?: string;
    /**
     * 
     * @type {number}
     * @memberof LocationRadiusModel
     */
    latitude?: number;
    /**
     * 
     * @type {number}
     * @memberof LocationRadiusModel
     */
    longitude?: number;
    /**
     * 
     * @type {number}
     * @memberof LocationRadiusModel
     */
    radius?: number;
}

/**
 * Check if a given object implements the LocationRadiusModel interface.
 */
export function instanceOfLocationRadiusModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "placeId" in value;

    return isInstance;
}

export function LocationRadiusModelFromJSON(json: any): LocationRadiusModel {
    return LocationRadiusModelFromJSONTyped(json, false);
}

export function LocationRadiusModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): LocationRadiusModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'placeId': json['placeId'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'latitude': !exists(json, 'latitude') ? undefined : json['latitude'],
        'longitude': !exists(json, 'longitude') ? undefined : json['longitude'],
        'radius': !exists(json, 'radius') ? undefined : json['radius'],
    };
}

export function LocationRadiusModelToJSON(value?: LocationRadiusModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'placeId': value.placeId,
        'address': value.address,
        'latitude': value.latitude,
        'longitude': value.longitude,
        'radius': value.radius,
    };
}

