/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SubscriptionPlanModel } from './SubscriptionPlanModel';
import {
    SubscriptionPlanModelFromJSON,
    SubscriptionPlanModelFromJSONTyped,
    SubscriptionPlanModelToJSON,
} from './SubscriptionPlanModel';

/**
 * 
 * @export
 * @interface SubscriptionPlansResponseModel
 */
export interface SubscriptionPlansResponseModel {
    /**
     * 
     * @type {Array<SubscriptionPlanModel>}
     * @memberof SubscriptionPlansResponseModel
     */
    plans: Array<SubscriptionPlanModel>;
}

/**
 * Check if a given object implements the SubscriptionPlansResponseModel interface.
 */
export function instanceOfSubscriptionPlansResponseModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "plans" in value;

    return isInstance;
}

export function SubscriptionPlansResponseModelFromJSON(json: any): SubscriptionPlansResponseModel {
    return SubscriptionPlansResponseModelFromJSONTyped(json, false);
}

export function SubscriptionPlansResponseModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubscriptionPlansResponseModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'plans': ((json['plans'] as Array<any>).map(SubscriptionPlanModelFromJSON)),
    };
}

export function SubscriptionPlansResponseModelToJSON(value?: SubscriptionPlansResponseModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'plans': ((value.plans as Array<any>).map(SubscriptionPlanModelToJSON)),
    };
}

