import {BOTTLEHOUNDS_API_NAME} from './constants';
import {
  GetProductsLcboProductsGetRequest,
  ProductModel,
  ProductSearchResultsModel,
  SearchSuggestionResults,
  SuggestionsLcboSuggestionsGetRequest,
} from './openapi';
import { useQuery } from '@tanstack/react-query';
import { get } from 'aws-amplify/api';

export const LCBO_PRODUCTS_API_PATH = '/lcbo/products';
export const LCBO_SEARCH_SUGGESTIONS_API_PATH = '/lcbo/suggestions';


export const getProduct = async (productId: string) =>
  (await (
    await get({
      apiName: BOTTLEHOUNDS_API_NAME,
      path: `${LCBO_PRODUCTS_API_PATH}/${productId}`,
    }).response
  ).body.json()) as unknown as ProductModel;


export const searchProducts = async (request: GetProductsLcboProductsGetRequest) =>
  await (await get({
    apiName: BOTTLEHOUNDS_API_NAME,
    path: LCBO_PRODUCTS_API_PATH,
    options: {
      queryParams: request as unknown as Record<string, string>,
    },
  }).response).body.json() as unknown as ProductSearchResultsModel;

export const searchSuggestions = async (
  request: SuggestionsLcboSuggestionsGetRequest,
) =>
  (await (
    await get({
      apiName: BOTTLEHOUNDS_API_NAME,
      path: LCBO_SEARCH_SUGGESTIONS_API_PATH,
      options: {
        queryParams: request as unknown as Record<string, string>,
      },
    }).response
  ).body.json()) as unknown as SearchSuggestionResults;

export const useGetProduct = (productId: string) =>
  useQuery({
    queryKey: [LCBO_PRODUCTS_API_PATH, productId],
    queryFn: () => getProduct(productId),
  });

export const useSearchProducts = (
  request: GetProductsLcboProductsGetRequest,
  enabled = false,
) =>
  useQuery({
    queryKey: [LCBO_PRODUCTS_API_PATH, request],
    queryFn: () => searchProducts(request),
    enabled,
  });

export const useSearchSuggestions = (
  request: SuggestionsLcboSuggestionsGetRequest,
  enabled = false,
) =>
  useQuery({
    queryKey: [LCBO_SEARCH_SUGGESTIONS_API_PATH, request],
    queryFn: () => searchSuggestions(request),
    enabled,
  });
