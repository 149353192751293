/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AlertSettingsModel
 */
export interface AlertSettingsModel {
    /**
     * 
     * @type {boolean}
     * @memberof AlertSettingsModel
     */
    emailAlerts?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AlertSettingsModel
     */
    pushNotifications?: boolean;
}

/**
 * Check if a given object implements the AlertSettingsModel interface.
 */
export function instanceOfAlertSettingsModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AlertSettingsModelFromJSON(json: any): AlertSettingsModel {
    return AlertSettingsModelFromJSONTyped(json, false);
}

export function AlertSettingsModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertSettingsModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'emailAlerts': !exists(json, 'emailAlerts') ? undefined : json['emailAlerts'],
        'pushNotifications': !exists(json, 'pushNotifications') ? undefined : json['pushNotifications'],
    };
}

export function AlertSettingsModelToJSON(value?: AlertSettingsModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'emailAlerts': value.emailAlerts,
        'pushNotifications': value.pushNotifications,
    };
}

