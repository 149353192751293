import { ScrollView, StyleSheet, View } from "react-native";
import { Modal, Portal, RadioButton, Surface, Text } from "react-native-paper";
import BottleButton from "../BottleButton";
import { getLocaleNodes, getLocaleString } from "../../utils/getLocaleString";
import { useAppTheme } from "../../theme";
import { ProductModel, WatchdogSettingsModel } from "../../api/openapi";
import { useState } from "react";
import BottleListItem from "../BottleListItem";

interface SwapPortalProps {
  loading: boolean;
  product: ProductModel;
  watchdogs: WatchdogSettingsModel[];
  swapWatchdog: (watchdogIdToDelete: string) => void;
  close: () => void;
}

const SwapPortal = ({
  loading,
  product,
  watchdogs,
  swapWatchdog,
  close,
}: SwapPortalProps) => {
  const [watchdogToDelete, setWatchdogToDelete] = useState<WatchdogSettingsModel>();
  const theme = useAppTheme();

  return (
    <Portal>
      <Modal
        visible={true}
        onDismiss={close}
        style={styles.modal}
        contentContainerStyle={styles.modal}>
        <Surface
          elevation={1}
          style={{
            borderColor: theme.colors.primary10,
            borderWidth: 1,
            borderTopColor: theme.colors.primary,
            borderTopWidth: 4,
            backgroundColor: theme.colors.primary5,
            ...styles.content,
          }}>
          {
            <View
              style={{
                ...styles.content,
                padding: 0,
              }}>
              <Text style={styles.title} variant="titleLarge">
                {getLocaleNodes('swapWatchdog', {
                  watchdog: (
                    <Text
                      key={product.id}
                      variant="titleLarge"
                      style={{fontWeight: 'bold', fontSize: 20}}>
                      {product.name}
                    </Text>
                  ),
                })}
              </Text>

              <ScrollView
                style={{
                  padding: 4,
                  marginTop: 8,
                  width: '100%',
                  height: 400,
                  maxHeight: '100%',
                }}>
                {watchdogs.map(watchdog => (
                  <BottleListItem
                    key={watchdog.id as string}
                    titleNumberOfLines={3}
                    title={watchdog.name}
                    left={() => (
                      <RadioButton.Item
                        onPress={() => setWatchdogToDelete(watchdog)}
                        key={watchdog.id}
                        label=""
                        value="plan"
                        style={{padding: 0, paddingRight: 0, margin: 'auto'}}
                        status={
                          watchdogToDelete?.id === watchdog.id
                            ? 'checked'
                            : 'unchecked'
                        }
                      />
                    )}
                    onPress={() => setWatchdogToDelete(watchdog)}
                    titleStyle={{padding: 0}}
                    contentStyle={{padding: 0, justifyContent: 'center'}}
                  />
                ))}
              </ScrollView>
            </View>
          }

          <View style={styles.actions}>
            <BottleButton
              textColor={theme.colors.secondary}
              disabled={loading}
              onPress={close}>
              {getLocaleString('cancel')}
            </BottleButton>
            <BottleButton
              icon="swap-horizontal"
              mode="contained"
              disabled={loading || !watchdogToDelete}
              loading={loading}
              onPress={() => swapWatchdog(watchdogToDelete?.id as string)}>
              {getLocaleString('swap')}
            </BottleButton>
          </View>
        </Surface>
      </Modal>
    </Portal>
  );
};

const styles = StyleSheet.create({
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 20,
  },
  content: {
    width: '100%',
    minHeight: 200,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'space-between',
    padding: 16,
    textAlign: 'center',
  },
  modal: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 4,
    paddingRight: 4,
    width: '100%',
    maxWidth: 600,
    margin: 'auto',
  },
  title: {
    marginBottom: 10,
    textAlign: 'center',
    fontSize: 20,
  },
});

export default SwapPortal;
