import { getCurrentUser } from "aws-amplify/auth";
import { signOutUser } from "../utils/user";

export const filterRetry = (failureCount: number, error: unknown): boolean => {

  if ((error as Error).message === 'Forbidden') {
    getCurrentUser().catch(() => signOutUser());
    return false;
  }

  if ([401, 403, 404].includes((error as {status: number}).status)) {
    return false;
  }

  return true;
};