/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AlertSettingsModel } from './AlertSettingsModel';
import {
    AlertSettingsModelFromJSON,
    AlertSettingsModelFromJSONTyped,
    AlertSettingsModelToJSON,
} from './AlertSettingsModel';
import type { LocationRadiusModel } from './LocationRadiusModel';
import {
    LocationRadiusModelFromJSON,
    LocationRadiusModelFromJSONTyped,
    LocationRadiusModelToJSON,
} from './LocationRadiusModel';
import type { SubscriptionDetailsModel } from './SubscriptionDetailsModel';
import {
    SubscriptionDetailsModelFromJSON,
    SubscriptionDetailsModelFromJSONTyped,
    SubscriptionDetailsModelToJSON,
} from './SubscriptionDetailsModel';
import type { WatchdogSettingsModel } from './WatchdogSettingsModel';
import {
    WatchdogSettingsModelFromJSON,
    WatchdogSettingsModelFromJSONTyped,
    WatchdogSettingsModelToJSON,
} from './WatchdogSettingsModel';

/**
 * 
 * @export
 * @interface SettingsResponseModel
 */
export interface SettingsResponseModel {
    /**
     * 
     * @type {AlertSettingsModel}
     * @memberof SettingsResponseModel
     */
    alertSettings?: AlertSettingsModel;
    /**
     * 
     * @type {AlertSettingsModel}
     * @memberof SettingsResponseModel
     */
    lcboSpecialAlerts?: AlertSettingsModel;
    /**
     * 
     * @type {boolean}
     * @memberof SettingsResponseModel
     */
    skippedLocation?: boolean;
    /**
     * 
     * @type {LocationRadiusModel}
     * @memberof SettingsResponseModel
     */
    locationRadius?: LocationRadiusModel;
    /**
     * 
     * @type {string}
     * @memberof SettingsResponseModel
     */
    email: string;
    /**
     * 
     * @type {Array<WatchdogSettingsModel>}
     * @memberof SettingsResponseModel
     */
    watchdogs: Array<WatchdogSettingsModel>;
    /**
     * 
     * @type {SubscriptionDetailsModel}
     * @memberof SettingsResponseModel
     */
    subscription?: SubscriptionDetailsModel;
    /**
     * 
     * @type {number}
     * @memberof SettingsResponseModel
     */
    maxWatchdogs: number;
}

/**
 * Check if a given object implements the SettingsResponseModel interface.
 */
export function instanceOfSettingsResponseModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "watchdogs" in value;
    isInstance = isInstance && "maxWatchdogs" in value;

    return isInstance;
}

export function SettingsResponseModelFromJSON(json: any): SettingsResponseModel {
    return SettingsResponseModelFromJSONTyped(json, false);
}

export function SettingsResponseModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SettingsResponseModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'alertSettings': !exists(json, 'alertSettings') ? undefined : AlertSettingsModelFromJSON(json['alertSettings']),
        'lcboSpecialAlerts': !exists(json, 'lcboSpecialAlerts') ? undefined : AlertSettingsModelFromJSON(json['lcboSpecialAlerts']),
        'skippedLocation': !exists(json, 'skippedLocation') ? undefined : json['skippedLocation'],
        'locationRadius': !exists(json, 'locationRadius') ? undefined : LocationRadiusModelFromJSON(json['locationRadius']),
        'email': json['email'],
        'watchdogs': ((json['watchdogs'] as Array<any>).map(WatchdogSettingsModelFromJSON)),
        'subscription': !exists(json, 'subscription') ? undefined : SubscriptionDetailsModelFromJSON(json['subscription']),
        'maxWatchdogs': json['maxWatchdogs'],
    };
}

export function SettingsResponseModelToJSON(value?: SettingsResponseModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'alertSettings': AlertSettingsModelToJSON(value.alertSettings),
        'lcboSpecialAlerts': AlertSettingsModelToJSON(value.lcboSpecialAlerts),
        'skippedLocation': value.skippedLocation,
        'locationRadius': LocationRadiusModelToJSON(value.locationRadius),
        'email': value.email,
        'watchdogs': ((value.watchdogs as Array<any>).map(WatchdogSettingsModelToJSON)),
        'subscription': SubscriptionDetailsModelToJSON(value.subscription),
        'maxWatchdogs': value.maxWatchdogs,
    };
}

