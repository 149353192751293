import { ReactElement, useEffect, useState } from "react";
import { NavigationContainer, Theme } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import {Hub} from 'aws-amplify/utils';

import SignIn from "./SignIn";
import SignUp from "./SignUp";
import { Image, StyleSheet } from "react-native";
import { AuthRoute, AuthStackParamList } from "./types";
import { getLocaleString } from "../../utils/getLocaleString";
import { IS_MOBILE } from "../../constants";
import ForgotPassword from "./ForgotPassword";
import { getCurrentUser } from "aws-amplify/auth";
import {
  getPermissionStatus,
  identifyUser,
  IdentifyUserInput,
  requestPermissions,
} from 'aws-amplify/push-notifications';


interface AuthStackProps {
  children: ReactElement;
  initialStep?: AuthRoute;
  theme: Theme;
}

const Stack = createStackNavigator<AuthStackParamList>();

const AuthStack = ({
  children,
  initialStep = "SignIn",
  theme,
}: AuthStackProps) => {
  const [userId, setUserId] = useState<string>();
  const [loading, setLoading] = useState(true);

  const linking = {
    prefixes: [
      'https://app.bottlehounds.ca/',
      'https://app.dev.bottlehounds.ca/',
      'http://localhost:8080/',
    ],
    config: {
      screens: {
        SignIn: '/signin',
        SignUp: '/signup',
        ForgotPassword: '/forgot-password',
      },
    },
  };

  const handlePushPermissions = async () => {
    const status = await getPermissionStatus();
    switch (status) {
      case 'shouldRequest':
      case 'shouldExplainThenRequest':
        await requestPermissions();
        break;
    }
  };

  useEffect(() => {
    Hub.listen("auth", async ({ payload: { event } }) => {
      switch (event) {
        case 'signedIn':
          setUserId((await getCurrentUser()).userId);
          break;
        case 'signedOut':
          setUserId(undefined);
          setLoading(false);
          break;
        case 'tokenRefresh':
          break;
      }
    });

    getCurrentUser()
      .then((user) => {
        setUserId(user.userId);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (!userId) {
      return;
    }

    if (IS_MOBILE) {
      handlePushPermissions().then(() => {});
      identifyUser({
        userId,
        options: {
          optOut: 'NONE',
        },
      } as IdentifyUserInput).then(() => {});
    }
  }, [userId])

  return userId ? (
    children
  ) : (
    <>
      {!loading && (
        <NavigationContainer
          linking={linking}
          theme={theme}
          documentTitle={{
            enabled: true,
            formatter: (options, route) =>
              `Bottle Hounds | ${options?.title ?? route?.name}`,
          }}>
          <Stack.Navigator
            initialRouteName={initialStep}
            screenOptions={{
              header: props => (
                <Image
                  style={styles.image}
                  source={require('../../../assets/bottlehounds_logo-min.png')}
                  accessibilityLabel={getLocaleString('bottlehoundsLogo')}
                  resizeMode="contain"
                  {...props}
                />
              ),
              headerMode: 'screen',
              cardStyle: styles.steps,
              presentation: 'card',
            }}>
            <Stack.Screen
              name="SignIn"
              component={SignIn}
              options={{title: getLocaleString('signIn')}}
            />
            <Stack.Screen
              name="SignUp"
              component={SignUp}
              options={{title: getLocaleString('signUp')}}
            />
            <Stack.Screen
              name="ForgotPassword"
              component={ForgotPassword}
              options={{title: getLocaleString('forgotPassword')}}
            />
          </Stack.Navigator>
        </NavigationContainer>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  image: {
    height: 120,
    width: 120,
    position: "relative",
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: 20,
    marginTop: "5%",
  },
  steps: {
    flex: 1,
    marginTop: 16,
    padding: 4,
    margin: "auto",
    width: "100%",
  },
});

export default AuthStack;
