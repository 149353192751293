import { BOTTLEHOUNDS_API_NAME } from './constants';
import {
  SettingsResponseModel,
  WatchdogRequestModel
} from './openapi';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { SETTINGS_API_PATH } from './settings';
import { del, post } from 'aws-amplify/api';

export const WATCHDOGS_API_PATH = '/watchdogs'

export const addWatchdog = async (request: WatchdogRequestModel) =>
  (await (
    await post({
      apiName: BOTTLEHOUNDS_API_NAME,
      path: WATCHDOGS_API_PATH,
      options: {body: request},
    }).response
  ).body.json()) as unknown as SettingsResponseModel;

export const deleteWatchdog = async (watchdogId: string) => await del({
  apiName: BOTTLEHOUNDS_API_NAME,
  path: `${WATCHDOGS_API_PATH}/${watchdogId}`,
}).response

export const useAddWatchdog = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: addWatchdog,
    onSuccess: () => 
        queryClient.invalidateQueries({queryKey: [SETTINGS_API_PATH]}),
  })
}

export const useDeleteWatchdog = () =>{
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteWatchdog,
    onSuccess: () =>
      queryClient.invalidateQueries({queryKey: [SETTINGS_API_PATH]}),
  });
}
