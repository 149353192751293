import { IconButton, Menu, Surface } from "react-native-paper";
import { useAppTheme } from "../../../theme";
import { MenuProps } from "./types";

const MobileMenu = ({
  children,
  isMenuVisible,
  setIsMenuVisible,
}: MenuProps) => {
  const theme = useAppTheme();

  return (
    <Menu
      visible={isMenuVisible}
      onDismiss={() => setIsMenuVisible(false)}
      anchorPosition="bottom"
      anchor={
        <IconButton
          size={28}
          style={{
            margin: 0,
            height: 'auto',
            width: 'auto',
          }}
          iconColor={theme.colors.primary}
          icon="menu"
          onPress={() => setIsMenuVisible(!isMenuVisible)}
        />
      }
      contentStyle={{
        backgroundColor: theme.colors.primary5,
        paddingTop: 0,
        paddingBottom: 0,
      }}>
      <Surface elevation={2} style={{backgroundColor: theme.colors.primary5}}>
        {children}
      </Surface>
    </Menu>
  );
};

export default MobileMenu;
