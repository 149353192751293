import {BOTTLEHOUNDS_API_NAME} from './constants';
import {useMutation, useQuery} from '@tanstack/react-query';
import {
  SubscriptionCheckoutResponseModel,
  SubscriptionConfigResponseModel,
  SubscriptionCreateResponseModel,
  SubscriptionPlanEnum,
  SubscriptionPlansResponseModel,
  SubscriptionPortalSessionModel,
} from './openapi';
import { filterRetry } from './retry';
import { get, post } from 'aws-amplify/api';

export const SUBSCRIPTIONS_API_PATH = '/subscriptions';
const SUBSCRIPTIONS_CONFIG_PATH = `${SUBSCRIPTIONS_API_PATH}/config`;
const SUBSCRIPTIONS_PLANS_PATH = `${SUBSCRIPTIONS_API_PATH}/plans`;

export const getSubscriptionConfig = async () =>
  await (await get({
    apiName: BOTTLEHOUNDS_API_NAME,
    path: SUBSCRIPTIONS_CONFIG_PATH,
  }).response).body.json() as unknown as SubscriptionConfigResponseModel;

export const getSubscriptionPlans = async () =>
  await (await get({
    apiName: BOTTLEHOUNDS_API_NAME,
    path: SUBSCRIPTIONS_PLANS_PATH,
  }).response).body.json() as unknown as SubscriptionPlansResponseModel;

export const getSubscriptionPortalSession = async () =>
  await ( await get({
    apiName: BOTTLEHOUNDS_API_NAME,
    path: `${SUBSCRIPTIONS_API_PATH}/portal`,
  }).response).body.json() as unknown as SubscriptionPortalSessionModel;

export const createSubscription = async (plan: SubscriptionPlanEnum) =>
  await (await post({
    apiName: BOTTLEHOUNDS_API_NAME,
    path: SUBSCRIPTIONS_API_PATH,
    options: {
      body: {plan},
    }
  }).response).body.json() as unknown as SubscriptionCreateResponseModel;

export const createSubscriptionCheckoutSession = async (plan: SubscriptionPlanEnum) =>
  await (await post({
    apiName: BOTTLEHOUNDS_API_NAME,
    path: `${SUBSCRIPTIONS_API_PATH}/checkout`,
    options: {
      body: {plan},
    }
  }).response).body.json() as unknown as SubscriptionCheckoutResponseModel;

export const useCreateSubscription = () =>
  useMutation({
    mutationFn: createSubscription,
  });

export const useGetSubscriptionPortalSession = () =>
  useQuery({
    queryKey: [],
    queryFn: getSubscriptionPortalSession,
    retry: filterRetry,
  });

export const useGetSubscriptionConfig = () =>
  useQuery({
    queryKey: [SUBSCRIPTIONS_CONFIG_PATH],
    queryFn: getSubscriptionConfig,
    retry: filterRetry,
  });

export const useCreateSubscriptionCheckoutSession = () =>
  useMutation({
    mutationFn: createSubscriptionCheckoutSession,
  });

export const useGetSubscriptionPlans = () =>
  useQuery({
    queryKey: [SUBSCRIPTIONS_PLANS_PATH],
    queryFn: getSubscriptionPlans,
    retry: filterRetry,
  });
