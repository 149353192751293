import {signOut} from 'aws-amplify/auth';

import {SettingsResponseModel, SubscriptionDetailsModel} from '../api/openapi';
import {WelcomeStepKey} from '../screens/Welcome/types';
import { queryClient } from '../api/client';

export const signOutUser = async () => {
  queryClient.clear();
  await signOut();
};

export const isUserActive = (
  subscription: SubscriptionDetailsModel,
): boolean => {
  const currentPeriodEnd = subscription?.currentPeriodEnd
    ? Date.parse(String(subscription?.currentPeriodEnd))
    : null;
  const trialEnd = subscription?.trialEnd
    ? Date.parse(String(subscription?.trialEnd))
    : null;

  const expirationDate =
    !currentPeriodEnd ||
    (trialEnd && currentPeriodEnd && trialEnd > currentPeriodEnd)
      ? trialEnd
      : currentPeriodEnd;

  return !!(expirationDate && expirationDate > Date.now());
};

export const getUserOnboardingStep = (
  settings: SettingsResponseModel,
): WelcomeStepKey | null => {
  if (settings?.alertSettings === null && !settings?.watchdogs.length) {
    return 'search';
  }
  if (settings?.locationRadius === null && !settings.skippedLocation) {
    return 'location';
  }
  if (settings?.alertSettings === null) {
    return 'alerts';
  }
  if (settings?.lcboSpecialAlerts === null) {
    return 'lcboSpecialAlerts';
  }
  return null;
};
