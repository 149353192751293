import { downloadData } from 'aws-amplify/storage';
import { useQuery } from "@tanstack/react-query";
import { WatchdogType } from "./openapi";
import { InventoryDiffModel } from "./types";

export const WATCHDOG_INVENTORY_KEY = 'WATCHDOG_INVENTORY';

export const getProductInventory = async (
  watchdogType: WatchdogType,
  productId: string,
): Promise<InventoryDiffModel | null> => {
  try {
    const {body} = await downloadData({
      path: `public/${watchdogType}/${productId}`,
    }).result;

    const inventoryDiff = JSON.parse(await body.text()) as InventoryDiffModel;

    // const lastModified = new Date(inventoryDiff.timestamp);
    // if (
    //   !inventoryDiff.timestamp ||
    //   new Date().getTime() - lastModified.getTime() > TEN_MINUTES_IN_MS
    // ) {
    //   return null;
    // }

    return inventoryDiff;
  } catch (reason: any) {
    if (reason.name == 'NoSuchKey') {
      return null;
    }
    throw reason;
  }
};

export const useGetProductInventory = (
  watchdogType: WatchdogType,
  productId: string,
) =>
  useQuery({
    queryKey: ['WATCHDOG_INVENTORY_KEY', watchdogType, productId],
    queryFn: () => getProductInventory(watchdogType, productId),
    refetchInterval: () => {
      return 270000 + Math.floor(Math.random() * 30000 + 1);
    },
    refetchIntervalInBackground: true,
  });
