/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AlertSettingsModel } from './AlertSettingsModel';
import {
    AlertSettingsModelFromJSON,
    AlertSettingsModelFromJSONTyped,
    AlertSettingsModelToJSON,
} from './AlertSettingsModel';
import type { LocationRadiusModel } from './LocationRadiusModel';
import {
    LocationRadiusModelFromJSON,
    LocationRadiusModelFromJSONTyped,
    LocationRadiusModelToJSON,
} from './LocationRadiusModel';

/**
 * 
 * @export
 * @interface SettingsRequestModel
 */
export interface SettingsRequestModel {
    /**
     * 
     * @type {AlertSettingsModel}
     * @memberof SettingsRequestModel
     */
    alertSettings?: AlertSettingsModel;
    /**
     * 
     * @type {AlertSettingsModel}
     * @memberof SettingsRequestModel
     */
    lcboSpecialAlerts?: AlertSettingsModel;
    /**
     * 
     * @type {boolean}
     * @memberof SettingsRequestModel
     */
    skippedLocation?: boolean;
    /**
     * 
     * @type {LocationRadiusModel}
     * @memberof SettingsRequestModel
     */
    locationRadius?: LocationRadiusModel;
}

/**
 * Check if a given object implements the SettingsRequestModel interface.
 */
export function instanceOfSettingsRequestModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SettingsRequestModelFromJSON(json: any): SettingsRequestModel {
    return SettingsRequestModelFromJSONTyped(json, false);
}

export function SettingsRequestModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SettingsRequestModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'alertSettings': !exists(json, 'alertSettings') ? undefined : AlertSettingsModelFromJSON(json['alertSettings']),
        'lcboSpecialAlerts': !exists(json, 'lcboSpecialAlerts') ? undefined : AlertSettingsModelFromJSON(json['lcboSpecialAlerts']),
        'skippedLocation': !exists(json, 'skippedLocation') ? undefined : json['skippedLocation'],
        'locationRadius': !exists(json, 'locationRadius') ? undefined : LocationRadiusModelFromJSON(json['locationRadius']),
    };
}

export function SettingsRequestModelToJSON(value?: SettingsRequestModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'alertSettings': AlertSettingsModelToJSON(value.alertSettings),
        'lcboSpecialAlerts': AlertSettingsModelToJSON(value.lcboSpecialAlerts),
        'skippedLocation': value.skippedLocation,
        'locationRadius': LocationRadiusModelToJSON(value.locationRadius),
    };
}

