import {useState} from 'react';
import {LayoutChangeEvent, View} from 'react-native';
import {Text, TextProps, useTheme} from 'react-native-paper';

const AndroidBottleTitle = ({children, ...props}: TextProps<string>) => {
  const theme = useTheme();

  const [top, setTop] = useState<number | null>(null);
  const [left, setLeft] = useState<number | null>(null);
  const [width, setWidth] = useState(0);
  const onLayout = (event: LayoutChangeEvent) => {
    setTop(event.nativeEvent.layout.y);
    setLeft(event.nativeEvent.layout.x);
    setWidth(event.nativeEvent.layout.width);
  };

  return (
    <View>
      <Text
        {...props}
        onLayout={onLayout}
        style={{
          zIndex: 100,
          ...(props.style ? (props.style as object) : {}),
        }}>
        {children}
      </Text>
      {left !== null && top !== null && (
        <Text
          {...props}
          style={{
            position: 'absolute',
            color: theme.colors.primary,
            textDecorationStyle: 'solid',
            textDecorationLine: 'underline',
            left,
            top,
            width,
            zIndex: 99,
            ...(props.style ? (props.style as object) : {}),
          }}>
          {children}
        </Text>
      )}
    </View>
  );
};

export default AndroidBottleTitle;
