import {MouseEvent} from 'react-native';

import {List, ListItemProps} from 'react-native-paper';
import {useAppTheme} from '../theme';

const BottleListItem = (props: ListItemProps & {key: string}) => {
  const theme = useAppTheme();

  return (
    <List.Item
      style={{
        backgroundColor: theme.colors.elevation.level1,
        borderLeftColor: theme.colors.primary,
        borderLeftWidth: 1,
        marginBottom: 4,
        marginTop: 4,
        ...(props.style ? (props.style as object) : {}),
      }}
      titleStyle={{
        fontSize: 18,
        ...(props.titleStyle ? (props.titleStyle as object) : {}),
      }}
      descriptionStyle={{
        fontSize: 16,
        ...(props.descriptionStyle ? (props.descriptionStyle as object) : {}),
      }}
      onHoverIn={(event: MouseEvent) =>
        ((
          event.target as unknown as ElementCSSInlineStyle
        ).style.backgroundColor = theme.colors.elevation.level3)
      }
      onHoverOut={(event: MouseEvent) =>
        ((
          event.target as unknown as ElementCSSInlineStyle
        ).style.backgroundColor = theme.colors.elevation.level1)
      }
      {...props}
    />
  );
};

export default BottleListItem;
