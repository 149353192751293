import { useState } from "react";
import { View, StyleSheet } from "react-native";
import { Subheading, TextInput, useTheme } from "react-native-paper";
import {signIn, signInWithRedirect} from 'aws-amplify/auth';
import { AuthStackScreenProps } from "../types";
import { getLocaleString } from "../../../utils/getLocaleString";
import BottleButton from "../../../components/BottleButton";
import NewPasswordRequried from "./NewPasswordRequired";
import VerifyEmail from "../VerifyEmail";
import AuthScreen from "../AuthScreen";


const SignIn = ({ navigation, route }: AuthStackScreenProps<"SignIn">) => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState(route.params?.email ?? "");
  const [error, setError] = useState(route?.params?.error ?? "");
  const [password, setPassword] = useState('');
  const [newPasswordRequired, setNewPasswordRequired] = useState(false);
  const [verifyEmail, setVerifyEmail] = useState(false);

  const { colors } = useTheme();

  const signInDisabled = !email || !password || loading;

  const handleSignIn = async () => {
    setVerifyEmail(false);
    setLoading(true);
    try {
      const {nextStep} = await signIn({username: email, password});

      switch (nextStep.signInStep) {
        case 'CONFIRM_SIGN_UP':
          setVerifyEmail(true);
          break;
        case 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED':
          setNewPasswordRequired(true);
          break;
      }
    } catch (error) {
      setError(getLocaleString('incorrectUsernameOrPassword'));
      setEmail('');
      setPassword('');
      setLoading(false);
    }
  };

  const signUp = () => {
    navigation.push("SignUp");
  };

  const forgotPassword = () => {
    navigation.push('ForgotPassword');
  };

  if (newPasswordRequired) {
    return <NewPasswordRequried navigation={navigation} />
  }

  if (verifyEmail) {
    return (
      <VerifyEmail email={email} onDone={handleSignIn} navigation={navigation} />
    );
  }

  return (
    <AuthScreen>
      <View style={styles.buttonGroup}>
        <BottleButton
          disabled={loading}
          style={styles.signInWithButton}
          icon="apple"
          accessibilityLabel={getLocaleString('signInWithApple')}
          onPress={() =>
            signInWithRedirect({
              provider: 'Apple',
            })
          }
          mode="contained">
          {getLocaleString('signInWithApple')}
        </BottleButton>

        <BottleButton
          disabled={loading}
          icon="google"
          accessibilityLabel={getLocaleString('signInWithGoogle')}
          onPress={() =>
            signInWithRedirect({
              provider: 'Google',
            })
          }
          mode="contained">
          {getLocaleString('signInWithGoogle')}
        </BottleButton>
      </View>

      <View style={styles.buttonGroup}>
        {!!error && (
          <Subheading style={{color: colors.error, textAlign: 'center'}}>
            {error}
          </Subheading>
        )}
        <TextInput
          disabled={loading}
          autoComplete="username"
          dense={true}
          keyboardType="email-address"
          label={getLocaleString('email')}
          mode="outlined"
          onChangeText={setEmail}
          returnKeyType="next"
          value={email}
          style={{marginBottom: 4}}
        />
        <TextInput
          disabled={loading}
          style={styles.signInButton}
          autoComplete="password"
          dense={true}
          label={getLocaleString('password')}
          mode="outlined"
          onChangeText={setPassword}
          secureTextEntry={true}
          blurOnSubmit={true}
          onSubmitEditing={handleSignIn}
          returnKeyType="send"
          value={password}
        />
        <BottleButton
          style={styles.signInButton}
          disabled={signInDisabled}
          mode="contained"
          onPress={handleSignIn}
          loading={loading}>
          {getLocaleString('signIn')}
        </BottleButton>
        <View style={{alignItems: 'center'}}>
          <BottleButton
            disabled={loading}
            mode="text"
            onPress={forgotPassword}
            textColor="white">
            {getLocaleString('forgotYourPassword')}
          </BottleButton>
        </View>
      </View>

      <View style={{alignItems: 'center'}}>
        <BottleButton disabled={loading} mode="text" onPress={signUp}>
          {getLocaleString('signUp')}
        </BottleButton>
      </View>
    </AuthScreen>
  );
};

const styles = StyleSheet.create({
  buttonGroup: {
    display: "flex",
    marginBottom: 20,
  },
  signInButton: {
    marginBottom: 10,
  },
  signInWithButton: {
    marginBottom: 10,
  },
});

export default SignIn;
