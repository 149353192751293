import './gesture-handler';
import 'i18n-js';
import {DarkTheme as NavigationDarkTheme} from '@react-navigation/native';
import {
  Provider as PaperProvider,
  Text,
  adaptNavigationTheme,
} from 'react-native-paper';
import {Linking, Platform, SafeAreaView, StatusBar, StyleSheet, View} from 'react-native';
import AuthStack from './screens/AuthStack';
import Home from './screens/Home';
import {SafeAreaProvider} from 'react-native-safe-area-context';
import {QueryClientProvider} from '@tanstack/react-query';
import {Amplify} from 'aws-amplify';
import {
  initializePushNotifications,
  onNotificationOpened,
  getLaunchNotification,
  onNotificationReceivedInForeground,
  OnNotificationOpenedInput,
  OnNotificationReceivedInForegroundInput,
} from 'aws-amplify/push-notifications';
import {PushNotificationMessage} from '@aws-amplify/rtn-push-notification';
import {AMPLIFY_CONFIG} from './aws/config';
import {IS_MOBILE} from './constants';
import {queryClient} from './api/client';
import {appTheme} from './theme';

Amplify.configure(AMPLIFY_CONFIG);

if (IS_MOBILE) {
  initializePushNotifications();

  const handleNotificationOpened: OnNotificationOpenedInput = notification => {
    if (notification.goToUrl) {
      Linking.openURL(notification.goToUrl);
    }
  };

  const handleNotificationForeground: OnNotificationReceivedInForegroundInput =
    notification => {
      console.log(notification);
    };

  onNotificationOpened(handleNotificationOpened);
  getLaunchNotification().then(
    (notification: PushNotificationMessage | null) => {
      if (notification) {
        handleNotificationOpened(notification);
      }
    },
  );
  onNotificationReceivedInForeground(handleNotificationForeground);
}

window.onunhandledrejection = event => {
  console.error(event);
};

const {DarkTheme: navigationTheme} = adaptNavigationTheme({
  reactNavigationDark: NavigationDarkTheme,
  materialDark: appTheme,
});

const App = () => {
  return (
    <PaperProvider theme={appTheme}>
      <SafeAreaView style={styles.root}>
        <StatusBar barStyle="light-content" />
        <View style={styles.content}>
          <View style={{flexGrow: 1}}>
            <AuthStack theme={navigationTheme}>
              <QueryClientProvider client={queryClient}>
                <Home theme={navigationTheme} />
              </QueryClientProvider>
            </AuthStack>
          </View>
          {Platform.OS === 'web' && (
            <View>
              <Text
                variant="bodySmall"
                style={{
                  textAlign: 'center',
                  paddingTop: 4,
                  paddingBottom: 8,
                }}>
                Bottle Hounds Inc. © 2024
              </Text>
            </View>
          )}
        </View>
      </SafeAreaView>
    </PaperProvider>
  );
};

const styles = StyleSheet.create({
  root: {
    flex: 1,
    height: '100%',
    backgroundColor: 'black',
  },
  content: {
    maxWidth: 1024,
    width: '100%',
    flex: 1,
    margin: 'auto',
    overflow: 'visible',
    height: '100%',
    backgroundColor: 'black',
  },
});

export default App;
