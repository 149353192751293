import { QueryCache, QueryClient } from "@tanstack/react-query";
import { fetchAuthSession, signOut } from "aws-amplify/auth";
import { filterRetry } from "./retry";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: filterRetry,
    },
  },
  queryCache: new QueryCache({
    onError: async error => {
      const status = (
        error as unknown as {response: {request: {status: number}}}
      )?.response?.request?.status;
      // TODO: I think this is what is signing people out
      if (status === 401 || status === 403) {
        try {
          await fetchAuthSession();
        } catch (error) {
          await signOut();
        }
      }
    },
  }),
});
