/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 * @export
 */
export const WatchdogType = {
    Lcbo: 'lcbo',
    LcboSpecialAlerts: 'lcbo_special_alerts'
} as const;
export type WatchdogType = typeof WatchdogType[keyof typeof WatchdogType];


export function WatchdogTypeFromJSON(json: any): WatchdogType {
    return WatchdogTypeFromJSONTyped(json, false);
}

export function WatchdogTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): WatchdogType {
    return json as WatchdogType;
}

export function WatchdogTypeToJSON(value?: WatchdogType | null): any {
    return value as any;
}

