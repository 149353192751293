import { post } from "aws-amplify/api";
import { BOTTLEHOUNDS_API_NAME } from "./constants";
import { useMutation } from "@tanstack/react-query";

export const ACCOUNT_API_PATH = '/account';

export const deleteAccount = async (confirm: string) =>
  (await (
    await post({
      apiName: BOTTLEHOUNDS_API_NAME,
      path: `${ACCOUNT_API_PATH}/delete`,
      options: {
        body: {confirm},
      },
    }).response
  ).body.json());

export const useDeleteAccount = () =>
  useMutation({
    mutationFn: deleteAccount,
  });
