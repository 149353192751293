import { useState } from 'react';
import { ScrollView, } from 'react-native-gesture-handler';
import { DataTable, Text } from 'react-native-paper';
import BottleLink from './BottleLink';
import { StoreInventoryWithDistance } from '../types';
import { View } from 'react-native';

interface InventoryTableProps {
  inventory: StoreInventoryWithDistance[];
};

export const InventoryTable = ({ inventory }: InventoryTableProps) => {
  const [page, setPage] = useState<number>(0);
  const numberOfItemsPerPage = 10;
  const from = page * numberOfItemsPerPage;
  const to = Math.min((page + 1) * numberOfItemsPerPage, inventory.length);

  return (
    <DataTable style={{backgroundColor: 'black', flex: 1, height: '100%'}}>
      <DataTable.Header>
        <DataTable.Title>Inventory</DataTable.Title>
        <DataTable.Title>Distance</DataTable.Title>
        <DataTable.Title>Address</DataTable.Title>
        <DataTable.Title>Phone</DataTable.Title>
      </DataTable.Header>

      <ScrollView style={{flex: 1, height: '100%'}}>
        {inventory.slice(from, to).map(row => (
          <DataTable.Row key={row.storeNumber}>
            <DataTable.Cell>{row.inventory}</DataTable.Cell>
            <DataTable.Cell>
              {row.distance ? `${Math.ceil(row.distance / 1000)} KM` : ''}
            </DataTable.Cell>
            <DataTable.Cell>
              <View>
                <Text>{row.address1}</Text>
                {
                  !!row.address2 &&
                  <Text>{row.address2}</Text>
                }
                <Text>{row.city}</Text>
              </View>
            </DataTable.Cell>
            <DataTable.Cell>
              <BottleLink variant="titleMedium" href={`tel:${row.phone}`}>
                {row.phone}
              </BottleLink>
            </DataTable.Cell>
          </DataTable.Row>
        ))}
      </ScrollView>

      <DataTable.Pagination
        page={page}
        numberOfPages={Math.ceil(inventory.length / numberOfItemsPerPage)}
        onPageChange={setPage}
        label={`${from + 1}-${to} of ${inventory.length}`}
        showFastPaginationControls
        numberOfItemsPerPage={numberOfItemsPerPage}
      />
    </DataTable>
  );
}
