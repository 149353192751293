import {useState} from 'react';
import {
  Icon,
  List,
  ListItemProps,
  Text,
} from 'react-native-paper';
import {ScrollView, StyleSheet, View} from 'react-native';
import { getLocaleNodes, getLocaleString } from '../../utils/getLocaleString';
import { WelcomeScreenProps } from './types';
import { AlertSettingsModel } from '../../api/openapi/models';
import { useUpdateSettings } from '../../api/settings';
import WatchdogWord from '../../components/WatchdogWord';
import BottleTitle from '../../components/BottleTitle';
import { IS_MOBILE, STOCK_STATUS_COLOR, STOCK_STATUS_ICON_SOURCE } from '../../constants';
import BottleSwitch from '../../components/BottleSwitch';
import BottleButton from '../../components/BottleButton';

const ListItem = (props: ListItemProps) => (
  <List.Item
    titleStyle={{
      fontWeight: '500',
      marginBottom: 1,
    }}
    {...props}
    style={{
      marginBottom: 4,
      padding: 0,
      paddingLeft: 4,
      paddingRight: 4,
      ...(props.style as object),
    }}
  />
);

const Alerts = ({setStep}: WelcomeScreenProps) => {
  const [state, setState] = useState<AlertSettingsModel>({
    emailAlerts: IS_MOBILE ? false : true,
    pushNotifications: true,
  });

  const updateSettings = useUpdateSettings();

  const onClickNext = async () => {
    await updateSettings.mutateAsync({alertSettings: state});
    setStep('lcboSpecialAlerts');
  };

  return (
    <View style={styles.root}>
      <View style={styles.heading}>
        <BottleTitle style={styles.text} variant="displaySmall">
          {getLocaleString('selectYourAlerts')}
        </BottleTitle>
      </View>

      <ScrollView>
        <View>
          <Text
            variant="headlineSmall"
            style={{marginBottom: 8, textAlign: 'justify'}}>
            {getLocaleNodes('yourWatchdogWillNotifyYouOfTheFollowingEvents', {
              watchdog: <WatchdogWord variant="headlineSmall" />,
            })}
          </Text>

          <ListItem
            title={getLocaleString('inStock')}
            description={getLocaleString('inStockDescription')}
            titleStyle={styles.listItemTitle}
            descriptionStyle={styles.listItemDescription}
            left={props => (
              <View style={styles.icon}>
                <Icon
                  source={STOCK_STATUS_ICON_SOURCE.inStock}
                  color={STOCK_STATUS_COLOR.inStock}
                  size={25}
                />
              </View>
            )}
            style={{
              ...styles.listItem,
            }}
          />

          <ListItem
            title={getLocaleString('lowStock')}
            description={getLocaleString('lowStockDescription')}
            titleStyle={styles.listItemTitle}
            descriptionStyle={styles.listItemDescription}
            left={props => (
              <View style={styles.icon}>
                <Icon
                  source={STOCK_STATUS_ICON_SOURCE.lowStock}
                  color={STOCK_STATUS_COLOR.lowStock}
                  size={25}
                />
              </View>
            )}
            style={{
              ...styles.listItem,
            }}
          />

          <ListItem
            title={getLocaleString('noStock')}
            description={getLocaleString('noStockDescription')}
            titleStyle={styles.listItemTitle}
            descriptionStyle={styles.listItemDescription}
            left={props => (
              <View style={styles.icon}>
                <Icon
                  source={STOCK_STATUS_ICON_SOURCE.noStock}
                  color={STOCK_STATUS_COLOR.noStock}
                  size={25}
                />
              </View>
            )}
            style={{
              ...styles.listItem,
            }}
          />
        </View>

        <View style={styles.switches}>
          <BottleSwitch
            switchProps={{
              disabled: updateSettings.isLoading,
              value: state.pushNotifications,
              onValueChange: () =>
                setState({
                  ...state,
                  pushNotifications: !state.pushNotifications,
                }),
            }}
            title={getLocaleString('pushNotifications')}
            description={getLocaleString('pushNotificationsDescription')}
          />

          <BottleSwitch
            switchProps={{
              disabled: updateSettings.isLoading,
              value: state.emailAlerts,
              onValueChange: () =>
                setState({...state, emailAlerts: !state.emailAlerts}),
            }}
            title={getLocaleString('emailAlerts')}
            description={getLocaleString('emailAlertsDescription')}
          />
        </View>

        <View style={styles.footer}>
          <Text variant="titleSmall" style={styles.footerText}>
            {getLocaleString('youCanChangeYourPreferencesAtAnyTime')}
          </Text>
          <BottleButton
            disabled={updateSettings.isLoading}
            mode="contained"
            loading={updateSettings.isLoading}
            onPress={onClickNext}>
            {getLocaleString('next')}
          </BottleButton>
        </View>
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    height: '100%',
  },
  heading: {
    textAlign: 'center',
    marginBottom: 30,
    marginTop: 20,
  },
  icon: {
    paddingLeft: 4,
    justifyContent: 'center',
  },
  footer: {
    marginTop: 8,
  },
  footerText: {
    marginBottom: 10,
    textAlign: 'center',
  },
  text: {
    textAlign: 'center',
  },
  switches: {
    width: '100%',
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 8,
    paddingRight: 8,
    flex: 1,
  },
  listItem: {
    marginBottom: 10,
  },
  listItemTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    marginLeft: -4,
  },
  listItemDescription: {
    fontSize: 18,
    marginLeft: -4,
  },
});

export default Alerts;