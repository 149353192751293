import { NavigationContainer, Theme } from '@react-navigation/native';
import { createStackNavigator } from "@react-navigation/stack";
import BottleBar from './BottleBar';
import Watchdogs from '../Watchdogs';
import { HomeStackParamList } from './types';
import Welcome from '../Welcome';
import Search from '../Search';
import { useGetSettings } from '../../api/settings';
import { useMemo } from 'react';
import Settings from '../Settings';
import { LINKS } from './constants';
import Subscribe from '../Subscribe';
import BottleLoader from '../../components/BottleLoader';
import { View } from 'react-native';
import NotificationSettings from '../Settings/Notifications';
import LocationSettings from '../Settings/Location';
import { getUserOnboardingStep, isUserActive } from '../../utils/user';
import { getLocaleString } from '../../utils/getLocaleString';
import SecuritySettings from '../Settings/Security';
import { WelcomeStepKey } from '../Welcome/types';
import WatchdogInventory from '../WatchdogInventory/WatchdogInventory';
import { SubscriptionDetailsModel } from '../../api/openapi';
import Inactive from '../Inactive';
import DangerZone from '../Settings/DangerZone';

const Stack = createStackNavigator<HomeStackParamList>();

interface Props {
  theme: Theme
}

const linking = {
  prefixes: LINKS,
  config: {
    screens: {
      Search: '/search',
      Settings: '/settings',
      DangerZone: '/settings/danger',
      LocationSettings: '/settings/location',
      NotificationSettings: '/settings/notifications',
      SecuritySettings: '/settings/security',
      Subscribe: '/subscribe',
      Inactive: '/inactive',
      WatchdogInventory: 'watchdogs/:id',
      Watchdogs: '/watchdogs',
      Welcome: '/welcome',
    },
  },
};

const getStack = (
  initialWelcomeStep: WelcomeStepKey | null,
  subscription: SubscriptionDetailsModel | null,
) => {
  if (initialWelcomeStep) {
    return (
      <>
        <Stack.Screen name="Welcome">
          {() => <Welcome initialStep={initialWelcomeStep} />}
        </Stack.Screen>
        <Stack.Screen
          name="Settings"
          component={Settings}
          options={{title: getLocaleString('settings')}}
        />
        <Stack.Screen
          name="DangerZone"
          component={DangerZone}
          options={{title: getLocaleString('dangerZone')}}
        />
        <Stack.Screen
          name="SecuritySettings"
          component={SecuritySettings}
          options={{title: getLocaleString('securitySettings')}}
        />
      </>
    );
  }

  const settings = (
    <>
      <Stack.Screen
        name="Settings"
        component={Settings}
        options={{title: getLocaleString('settings')}}
      />
      <Stack.Screen
        name="NotificationSettings"
        component={NotificationSettings}
        options={{title: getLocaleString('notificationSettings')}}
      />
      <Stack.Screen
        name="LocationSettings"
        component={LocationSettings}
        options={{title: getLocaleString('locationSettings')}}
      />
      <Stack.Screen
        name="SecuritySettings"
        component={SecuritySettings}
        options={{title: getLocaleString('securitySettings')}}
      />
      <Stack.Screen
        name="DangerZone"
        component={DangerZone}
        options={{title: getLocaleString('dangerZone')}}
      />
    </>
  );

  if (!subscription?.subscriptionId) {
    return (
      <>
        <Stack.Screen name="Subscribe" component={Subscribe} />
        {settings}
      </>
    );
  }

  if (subscription && !isUserActive(subscription)) {
    return (
      <>
        <Stack.Screen name="Inactive" component={Inactive} />
        {settings}
      </>
    );
  }

  return (
    <>
      <Stack.Screen
        name="Watchdogs"
        component={Watchdogs}
        options={{title: getLocaleString('watchdogs')}}
      />
      <Stack.Screen
        name="WatchdogInventory"
        component={WatchdogInventory}
        getId={({params}) => params.id}
        options={{title: 'Watchdog Inventory'}}
      />
      <Stack.Screen
        name="Search"
        component={Search}
        options={{title: getLocaleString('search')}}
      />
      {settings}
    </>
  );
};

const Home = ({theme}: Props) => {
  const {data: settings, isLoading} = useGetSettings();

  const isActive = (
    settings?.subscription ? isUserActive(settings?.subscription) : false
  );
  
  const initialRoute = isActive ? undefined : 'Subscribe';
  
  const initialWelcomeStep = useMemo(
    (): WelcomeStepKey | null =>
      settings ? getUserOnboardingStep(settings) : null,
    [settings],
  );

  const stack = getStack(initialWelcomeStep, settings?.subscription || null);

  return isLoading ? (
    <View
      style={{
        height: '100%',
        width: '100%',
        flex: 1,
      }}>
      <BottleLoader size="large" style={{margin: 'auto'}} />
    </View>
  ) : (
    <NavigationContainer
      linking={linking}
      theme={theme}
      documentTitle={{
        enabled: true,
        formatter: (options, route) =>
          `Bottle Hounds | ${options?.title ?? route?.name}`,
      }}>
      <Stack.Navigator
        initialRouteName={initialWelcomeStep ? 'Welcome' : initialRoute}
        screenOptions={{
          header: props => <BottleBar {...props} />,
          headerMode: 'screen',
          presentation: 'card',
          cardStyle: {
            padding: 4,
            overflow: 'visible',
          },
        }}>
        {stack}
      </Stack.Navigator>
    </NavigationContainer>
  );
}

export default Home;
