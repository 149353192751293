import { Text } from "react-native-paper";
import BottleButton from "../../../components/BottleButton";
import { getLocaleString } from "../../../utils/getLocaleString";
import { StyleSheet, View } from "react-native";
import Purchases from "react-native-purchases";
import { useState } from "react";
import { SUBSCRIPTION_PATH, useGetSubscription } from "../../../api/subscription";
import { SETTINGS_API_PATH, useGetSettings } from "../../../api/settings";
import { queryClient } from "../../../api/client";
import { IS_MOBILE } from "../../../constants";
import { isUserActive } from "../../../utils/user";

const MAX_RESTORE_TRIES = 120;


const Restore = () => {
  const [restoreLoading, setRestoreLoading] = useState(false);
  const [restoreTries, setRestoreTries] = useState(0);

  const {data: settingsData} = useGetSettings();
  const {data: subscriptionData} =
    useGetSubscription(IS_MOBILE);

  const isActive =
    !!settingsData?.subscription && isUserActive(settingsData?.subscription);

  const invalidateCaches = () => {
    queryClient.invalidateQueries({queryKey: [SUBSCRIPTION_PATH]});
    queryClient.invalidateQueries({queryKey: [SETTINGS_API_PATH]});
  };

  const refresh = () => {
    if (!isActive && restoreTries <= MAX_RESTORE_TRIES) {
      setRestoreTries(restoreTries + 1);
      setTimeout(refresh, 1200);
      invalidateCaches();
      return;
    }
    setRestoreLoading(false);
    setRestoreTries(0);
  };

  const restore = async () => {
    setRestoreLoading(true);
    await Purchases.logIn(subscriptionData?.customerId as string);
    const restore = await Purchases.restorePurchases();
    if (Object.keys(restore.entitlements.active).length == 0) {
      setRestoreLoading(false);
      return;
    }
    refresh();
  };

  return (
    <View
      style={{
        maxWidth: 700,
        width: '100%',
        margin: 'auto',
        alignItems: 'center',
      }}>
      <Text variant="titleLarge" style={styles.descriptionText}>
        {getLocaleString('restoreDescription')}
      </Text>
      <BottleButton
        icon="open-in-app"
        loading={restoreLoading}
        disabled={restoreLoading}
        mode="contained"
        onPress={restore}>
        {getLocaleString('restore')}
      </BottleButton>
    </View>
  );
}

const styles = StyleSheet.create({
  descriptionText: {
    textAlign: 'center',
    marginTop: 10,
    marginBottom: 40,
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 20,
  },
  content: {
    width: '100%',
    minHeight: 100,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'space-between',
    padding: 12,
    textAlign: 'center',
  },
  modal: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 4,
    paddingRight: 4,
    width: '100%',
    maxWidth: 500,
    margin: 'auto',
  },
});

export default Restore;