import {Linking, ScrollView, StyleSheet, View} from 'react-native';
import BottleTitle from '../../../components/BottleTitle';
import {getLocaleNodes, getLocaleString} from '../../../utils/getLocaleString';
import {Text} from 'react-native-paper';
import BottleButton from '../../../components/BottleButton';
import {useState} from 'react';
import {useGetSettings} from '../../../api/settings';
import BottleLoader from '../../../components/BottleLoader';
import {isUserActive} from '../../../utils/user';
import {HomeStackScreenProps} from '../../Home/types';
import {IS_MOBILE, IS_WEB} from '../../../constants';
import {
  getSubscriptionPortalSession,
  useGetSubscription,
} from '../../../api/subscription';
import Keyword from '../../../components/Keyword';
import {SubscriptionPlan} from '../../../api/openapi';
import Restore from './Restore';
import ChangePlan from './ChangePlan';

const PLAN_STRINGS = {
  premium: 'Premium',
  standard: 'Standard',
  lite: 'Lite',
};

const Subscription = ({
  navigation,
}: HomeStackScreenProps<'SubscriptionSettings'>) => {
  const [loading, setLoading] = useState(false);
  const {isLoading: isSettingsLoading, data: settingsData} = useGetSettings();
  const {isLoading: isSubscriptionLoading, data: subscriptionData} =
    useGetSubscription(IS_MOBILE);

  const isActive =
    !!settingsData?.subscription && isUserActive(settingsData?.subscription);

  const openManagementUrl = () => {
    if (settingsData?.subscription?.managementUrl) {
      Linking.openURL(settingsData?.subscription?.managementUrl);
    }
  };

  const openStripePortal = async () => {
    setLoading(true);
    const portalSession = await getSubscriptionPortalSession();
    if (portalSession?.url) {
      Linking.openURL(portalSession.url);
    }
    setLoading(false);
  };

  const viewPlans = () => navigation.push('Subscribe', {});

  const showLoading = isSettingsLoading || (IS_MOBILE && isSubscriptionLoading);

  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        height: '100%',
        marginTop: 20,
        padding: 4,
      }}>
      {showLoading && (
        <View style={{height: '100%', width: '100%', margin: 'auto'}}>
          <BottleLoader size="large" />
        </View>
      )}

      {!showLoading && (
        <ScrollView style={{width: '100%'}}>
          {isActive && (
            <View style={styles.section}>
              <BottleTitle variant="headlineMedium" style={styles.title}>
                {getLocaleString('subscription')}
              </BottleTitle>
              <View
                style={{
                  maxWidth: 700,
                  width: '100%',
                  margin: 'auto',
                  alignItems: 'center',
                }}>
                <Text
                  variant="titleLarge"
                  style={{
                    marginBottom: 30,
                    textAlign: 'center',
                  }}>
                  {getLocaleNodes('currentPlan', {
                    plan: (
                      <Keyword variant="titleLarge" key="plan">
                        {
                          PLAN_STRINGS[
                            settingsData?.subscription?.plan as SubscriptionPlan
                          ]
                        }
                      </Keyword>
                    ),
                  })}
                </Text>
                {settingsData?.subscription?.store === 'stripe' && (
                  <>
                    <Text variant="titleLarge" style={styles.descriptionText}>
                      {getLocaleString('subscriptionHandledByStripe')}
                    </Text>
                    <BottleButton
                      icon="open-in-new"
                      loading={loading}
                      disabled={loading}
                      mode="contained"
                      onPress={openStripePortal}>
                      {getLocaleString('manage')}
                    </BottleButton>
                  </>
                )}

                {(settingsData?.subscription?.store === 'app_store' ||
                  settingsData?.subscription?.store === 'play_store') &&
                  IS_WEB && (
                    <>
                      <Text variant="titleLarge" style={styles.descriptionText}>
                        {getLocaleString('subscriptionHandledOnMobile')}
                      </Text>
                      <BottleButton
                        icon="open-in-new"
                        mode="contained"
                        onPress={openManagementUrl}>
                        {getLocaleString('manage')}
                      </BottleButton>
                    </>
                  )}

                {(settingsData?.subscription?.store === 'app_store' ||
                  settingsData?.subscription?.store === 'play_store') &&
                  IS_MOBILE && (
                    <>
                      <ChangePlan />
                      <Text variant="titleLarge" style={styles.descriptionText}>
                        {getLocaleString(
                          subscriptionData?.store === 'app_store'
                            ? 'subscriptionHandledByAppStore'
                            : 'subscriptionHandledByPlayStore',
                        )}
                      </Text>
                      <BottleButton
                        icon="open-in-new"
                        mode="contained"
                        onPress={openManagementUrl}>
                        {getLocaleString('manage')}
                      </BottleButton>
                    </>
                  )}
              </View>
            </View>
          )}

          {!isActive && (
            <>
              <View style={styles.section}>
                <View
                  style={{
                    maxWidth: 700,
                    width: '100%',
                    margin: 'auto',
                    alignItems: 'center',
                  }}>
                  <Text variant="titleLarge" style={styles.descriptionText}>
                    {getLocaleString('noSubscriptionDescription')}
                  </Text>
                  <BottleButton mode="contained" onPress={viewPlans}>
                    {getLocaleString('viewPlans')}
                  </BottleButton>
                </View>
              </View>

              {IS_MOBILE && (
                <View style={{...styles.section, marginTop: 60}}>
                  <BottleTitle variant="headlineMedium" style={styles.title}>
                    {getLocaleString('restoreSubscription')}
                  </BottleTitle>
                  <Restore />
                </View>
              )}
            </>
          )}
        </ScrollView>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  section: {
    marginBottom: 10,
    width: '100%',
    flex: 1,
  },
  descriptionText: {
    textAlign: 'center',
    marginTop: 10,
    marginBottom: 40,
  },
  title: {
    marginBottom: 15,
    textAlign: 'center',
  },
});

export default Subscription;
