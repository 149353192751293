import { useState } from "react";
import { Subheading, useTheme } from "react-native-paper";
import { StyleSheet } from "react-native";
import { TextInput } from "react-native-paper";
import { AuthStackParamList } from "../types";
import { getLocaleString } from "../../../utils/getLocaleString";
import BottleButton from "../../../components/BottleButton";
import { StackNavigationProp } from "@react-navigation/stack";
import AuthScreen from "../AuthScreen";
import { confirmSignIn } from "aws-amplify/auth";


type NewPasswordRequired = {
  navigation: StackNavigationProp<AuthStackParamList>;
};


const NewPasswordRequried = ({navigation}: NewPasswordRequired) => {
  const [error, setError] = useState('');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const {colors} = useTheme();

  const submitDisabled = !password || password !== confirmPassword || loading;

  const confirmPasswordError = !!(
    confirmPassword && confirmPassword !== password
  );

  const submit = async () => {
    setLoading(true);
    setError('');
    setPasswordError('');

    try {
      await confirmSignIn({
        challengeResponse: password,
      });
    } catch (error) {
      switch ((error as {name: string}).name) {
        case 'InvalidPasswordException':
          setError(getLocaleString('invalidPassword'));
          setPassword('');
          setConfirmPassword('');
          break;
        default:
          navigation.push('SignIn', {error: getLocaleString('sww')});
          break;
      }
    }
    setLoading(false);
  };

  return (
    <AuthScreen>
      {!!error && (
        <Subheading style={{color: colors.error, textAlign: 'center'}}>
          {error}
        </Subheading>
      )}

      <TextInput
        autoComplete="off"
        dense={true}
        error={!!passwordError}
        label={getLocaleString('password')}
        mode="outlined"
        onChangeText={setPassword}
        secureTextEntry={true}
        value={password}
        disabled={loading}
      />

      <TextInput
        autoComplete="off"
        dense={true}
        label={getLocaleString('confirmPassword')}
        mode="outlined"
        onChangeText={setConfirmPassword}
        secureTextEntry={true}
        value={confirmPassword}
        disabled={loading}
        blurOnSubmit={true}
        onSubmitEditing={submit}
        returnKeyType="send"
        error={confirmPasswordError}
      />
      <BottleButton
        disabled={submitDisabled}
        mode="contained"
        onPress={submit}
        style={styles.button}>
        {getLocaleString('changePassword')}
      </BottleButton>
    </AuthScreen>
  );
};

const styles = StyleSheet.create({
  button: {
    marginTop: 10,
  },
});

export default NewPasswordRequried;
