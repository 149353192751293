/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SubscriptionPlanEnum } from './SubscriptionPlanEnum';
import {
    SubscriptionPlanEnumFromJSON,
    SubscriptionPlanEnumFromJSONTyped,
    SubscriptionPlanEnumToJSON,
} from './SubscriptionPlanEnum';

/**
 * 
 * @export
 * @interface SubscriptionDetailsModel
 */
export interface SubscriptionDetailsModel {
    /**
     * 
     * @type {string}
     * @memberof SubscriptionDetailsModel
     */
    customerId: string;
    /**
     * 
     * @type {SubscriptionPlanEnum}
     * @memberof SubscriptionDetailsModel
     */
    plan: SubscriptionPlanEnum;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionDetailsModel
     */
    productId: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionDetailsModel
     */
    subscriptionId: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionDetailsModel
     */
    subscriptionStatus: string;
    /**
     * 
     * @type {Date}
     * @memberof SubscriptionDetailsModel
     */
    currentPeriodEnd?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SubscriptionDetailsModel
     */
    trialEnd?: Date;
}

/**
 * Check if a given object implements the SubscriptionDetailsModel interface.
 */
export function instanceOfSubscriptionDetailsModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "customerId" in value;
    isInstance = isInstance && "plan" in value;
    isInstance = isInstance && "productId" in value;
    isInstance = isInstance && "subscriptionId" in value;
    isInstance = isInstance && "subscriptionStatus" in value;

    return isInstance;
}

export function SubscriptionDetailsModelFromJSON(json: any): SubscriptionDetailsModel {
    return SubscriptionDetailsModelFromJSONTyped(json, false);
}

export function SubscriptionDetailsModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubscriptionDetailsModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'customerId': json['customerId'],
        'plan': SubscriptionPlanEnumFromJSON(json['plan']),
        'productId': json['productId'],
        'subscriptionId': json['subscriptionId'],
        'subscriptionStatus': json['subscriptionStatus'],
        'currentPeriodEnd': !exists(json, 'currentPeriodEnd') ? undefined : (new Date(json['currentPeriodEnd'])),
        'trialEnd': !exists(json, 'trialEnd') ? undefined : (new Date(json['trialEnd'])),
    };
}

export function SubscriptionDetailsModelToJSON(value?: SubscriptionDetailsModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'customerId': value.customerId,
        'plan': SubscriptionPlanEnumToJSON(value.plan),
        'productId': value.productId,
        'subscriptionId': value.subscriptionId,
        'subscriptionStatus': value.subscriptionStatus,
        'currentPeriodEnd': value.currentPeriodEnd === undefined ? undefined : (value.currentPeriodEnd.toISOString()),
        'trialEnd': value.trialEnd === undefined ? undefined : (value.trialEnd.toISOString()),
    };
}

