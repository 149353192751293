import {useEffect, useMemo, useRef, useState} from 'react';
import {
  FlatList,
  View,
  StyleSheet,
  ListRenderItem,
  LayoutChangeEvent,
  ScrollView,
  TouchableWithoutFeedback,
  TouchableOpacity,
} from 'react-native';
import {ActivityIndicator, List, Text, useTheme} from 'react-native-paper';
import {BottleCard} from '../BottleCard';
import {useSearchProducts, useSearchSuggestions} from '../../api/lcbo';
import {ProductModel} from '../../api/openapi';
import {useAddWatchdog, useDeleteWatchdog} from '../../api/watchdogs';
import {useGetSettings} from '../../api/settings';
import Searchbar from '../../design-system/SearchBar';
import {getLocaleString} from '../../utils/getLocaleString';
import BottleButton from '../BottleButton';
import SwapPortal from './SwapPortal';
import BottleLoader from '../BottleLoader';

type BottleSearchProps = {
  onAdd?: () => void;
  onSearch?: () => void;
};

const BottleSearch = ({onAdd, onSearch}: BottleSearchProps) => {
  let suggestionsTimeout: NodeJS.Timeout | null = null;

  const theme = useTheme();
  const searchbarRef = useRef(null);

  const [input, setInput] = useState('');
  const [layoutHeight, setLayoutHeight] = useState<number>();
  const [fetchSuggestions, setFetchSuggestions] = useState(false);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [suggestionsLeft, setSuggestionsLeft] = useState<number>();
  const [suggestionsTop, setSuggestionsTop] = useState<number>();
  const [suggestionsWidth, setSuggestionsWidth] = useState<number>();
  const [searchResultsHeight, setSearchResultsHeight] = useState<number>();
  const [searchResultsTop, setSearchResultsTop] = useState<number>();

  const [query, setQuery] = useState('');
  const [skip, setSkip] = useState(0);
  const [searchResults, setSearchResults] = useState<ProductModel[]>([]);
  const [totalResults, setTotalResults] = useState<number>();
  const [productLoading, setProductLoading] = useState<string | null>();
  const [watchdogForSwap, setWatchdogForSwap] = useState<ProductModel>();

  const addWatchdog = useAddWatchdog();
  const deleteWatchdog = useDeleteWatchdog();
  const searchSuggestions = useSearchSuggestions(
    {query: input},
    !!input && fetchSuggestions
  );
  const searchProducts = useSearchProducts(
    {
      query,
      skip,
    },
    !!query,
  );
  const {data: settings} = useGetSettings();

  const maxSuggestionsHeight = (
    layoutHeight && suggestionsTop ?
    (layoutHeight - suggestionsTop) : 0
  );

  const watchdogsAvailable = settings
    ? settings.maxWatchdogs - settings.watchdogs.length
    : 0;

  const productIds = settings?.watchdogs.map(watchdog => watchdog.id) ?? [];

  const suggestions = useMemo(
    () =>
      Array.from(
        new Set(
          searchSuggestions.data?.suggestions.map(
            suggestion => suggestion.expression,
          ),
        ),
      ),
    [searchSuggestions.data?.suggestions],
  );

  useEffect(() => {
    if (searchProducts.isSuccess && searchProducts.data) {
      setSearchResults(searchResults.concat(searchProducts.data.products));
      setTotalResults(searchProducts.data.total);
    }
  }, [searchProducts.isSuccess && searchProducts.data]);

  const onLayoutChange = (event: LayoutChangeEvent) => {
    setLayoutHeight(event.nativeEvent.layout.height);
  };

  const onSearchbarLayoutChange = (event: LayoutChangeEvent) => {
    setSuggestionsLeft(event.nativeEvent.layout.x);
    setSuggestionsTop(
      event.nativeEvent.layout.y + event.nativeEvent.layout.height + 1,
    );
    setSuggestionsWidth(event.nativeEvent.layout.width);
  };

  const onSearchResultsLayoutChange = (event: LayoutChangeEvent) => {
    setSearchResultsHeight(event.nativeEvent.layout.height);
    setSearchResultsTop(event.nativeEvent.layout.y);
  };

  const handleInput = (value: string) => {
    setShowSuggestions(true);
    setFetchSuggestions(false);
    setInput(value);
    if (suggestionsTimeout) {
      clearTimeout(suggestionsTimeout);
    }
    if (value) {
      suggestionsTimeout = setTimeout(() => {
        suggestionsTimeout = null;
        setFetchSuggestions(true);
      }, 200);
    }
  };

  const handleSuggestionClick = (suggestion: string) => {
    setInput(suggestion);
    search(suggestion);
  };

  const addProduct = async (product: ProductModel) => {
    setProductLoading(product.id);
    await addWatchdog.mutateAsync({product, type: 'lcbo'});
    setProductLoading(null);
    if (onAdd) {
      onAdd();
    }
  };

  const showSwapWatchdog = (product: ProductModel) => {
    setWatchdogForSwap(product);
  };

  const closeSwapWatchdog = () => {
    setProductLoading(null);
    setWatchdogForSwap(undefined);
  };

  const swapWatchdog = async (watchdogIdToDelete: string) => {
    setWatchdogForSwap(undefined);
    if (watchdogForSwap) {
      setProductLoading(watchdogForSwap?.id);
      await deleteWatchdog.mutateAsync(watchdogIdToDelete);
      await addWatchdog.mutateAsync({product: watchdogForSwap, type: 'lcbo'});
    }
    setProductLoading(null);
  };

  const clear = () => {
    setInput('');
    setQuery('');
    setTotalResults(0);
    setSearchResults([]);
    setShowSuggestions(false);
  };

  const search = (value: string) => {
    if (onSearch) {
      onSearch();
    }
    setShowSuggestions(false);
    setSearchResults([]);
    setTotalResults(0);
    setQuery(value.trim());
    setSkip(0);
  };

  const onEndReached = () => {
    if (searchResults.length !== totalResults && !searchProducts.isFetching)
      setSkip(searchResults.length);
  };

  const Loadingcomponent = (
    <ActivityIndicator
      animating={searchProducts.isFetching}
      size="large"
      style={styles.loading}
    />
  );

  const renderItem: ListRenderItem<ProductModel> = ({item}) => {
    const isActiveWatchdog = productIds.includes(item.id);
    return (
      <TouchableOpacity activeOpacity={1} style={{cursor: 'auto'}}>
        <BottleCard
          key={item.id}
          bottomAction={
            <BottleButton
              style={styles.itemButton}
              icon={
                isActiveWatchdog
                  ? ''
                  : watchdogsAvailable
                  ? 'plus'
                  : 'swap-horizontal'
              }
              accessibilityLabel={getLocaleString('track')}
              onPress={
                watchdogsAvailable
                  ? () => addProduct(item)
                  : () => showSwapWatchdog(item)
              }
              disabled={!!productLoading || isActiveWatchdog}
              loading={productLoading == item.id}
              mode="contained">
              {isActiveWatchdog
                ? getLocaleString('active')
                : watchdogsAvailable
                ? getLocaleString('track')
                : getLocaleString('swap')}
            </BottleButton>
          }
          product={item}
        />
      </TouchableOpacity>
    );
  };

  return (
    <TouchableWithoutFeedback
      onPress={() => setShowSuggestions(false)}>
      <View style={styles.container} onLayout={onLayoutChange}>
        <Searchbar
          autoComplete="off"
          placeholder={getLocaleString('bottleSearchExample')}
          onChangeText={handleInput}
          value={input}
          onIconPress={() => search(input)}
          onSubmitEditing={() => search(input)}
          onPress={() => setShowSuggestions(true)}
          clearIcon="close"
          onClearIconPress={clear}
          style={styles.searchBar}
          mode="bar"
          inputStyle={{
            color: theme.colors.onSurface,
            fontSize: 18,
          }}
          placeholderTextColor="#eeeeee"
          onLayout={onSearchbarLayoutChange}
        />

        {!!(
          searchbarRef &&
          showSuggestions &&
          suggestionsTop &&
          layoutHeight
        ) && (
          <View
            style={{
              position: 'absolute',
              width: suggestionsWidth,
              left: suggestionsLeft,
              top: suggestionsTop,
              zIndex: 999,
              maxHeight:
                maxSuggestionsHeight > 300 ? 300 : maxSuggestionsHeight,
              padding: 0,
              paddingRight: 8,
              marginTop: 4,
              backgroundColor: 'black',
            }}
            onStartShouldSetResponder={() => true}>
            <ScrollView>
              {suggestions.map(suggestion => (
                <List.Item
                  key={suggestion}
                  onPress={() => handleSuggestionClick(suggestion)}
                  title={suggestion}
                  contentStyle={{paddingLeft: 0}}
                />
              ))}
            </ScrollView>
          </View>
        )}

        {!searchResults.length && searchProducts.isFetching && (
          <View style={{margin: 'auto'}}>
            <BottleLoader size="large" />
          </View>
        )}

        {!searchProducts?.data?.total &&
          !searchProducts.isFetching &&
          searchProducts.isSuccess && (
            <View style={{margin: 'auto'}}>
              <Text style={{textAlign: 'center'}} variant="displaySmall">
                {getLocaleString('noResults')}
              </Text>
              <Text
                style={{textAlign: 'center', paddingTop: 8}}
                variant="titleLarge">
                {getLocaleString('pleaseChangeYourSearch')}
              </Text>
            </View>
          )}

        {!!searchResults.length && (
          <FlatList
            data={searchResults}
            renderItem={renderItem}
            keyExtractor={product => product.id}
            onEndReached={onEndReached}
            onEndReachedThreshold={1}
            style={styles.results}
            ListFooterComponent={
              searchResults.length && searchProducts.isFetching
                ? Loadingcomponent
                : null
            }
            ListFooterComponentStyle={styles.footer}
            onLayout={onSearchResultsLayoutChange}
          />
        )}
        {!!(searchResults.length && showSuggestions) && (
          <View
            style={{
              position: 'absolute',
              top: searchResultsTop,
              height: searchResultsHeight,
              width: '100%',
              zIndex: 888,
              backgroundColor: theme.colors.backdrop,
            }}
          />
        )}
        {!!watchdogForSwap && (
          <SwapPortal
            product={watchdogForSwap}
            loading={!!productLoading}
            watchdogs={settings?.watchdogs || []}
            swapWatchdog={swapWatchdog}
            close={closeSwapWatchdog}
          />
        )}
      </View>
    </TouchableWithoutFeedback>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexGrow: 1,
    height: '100%',
  },
  loading: {
    flexGrow: 1,
    margin: 'auto',
  },
  results: {
    height: '100%',
  },
  searchBar: {
    backgroundColor: 'black',
    borderBottomWidth: 1,
    borderBottomColor: 'white',
    marginBottom: 10,
  },
  footer: {
    margin: 'auto',
    height: 80,
  },
  itemButton: {
    width: '100%',
    padding: 0,
  },
});

export default BottleSearch;
