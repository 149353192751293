import { useEffect, useState } from "react";
import { FlatList, View, StyleSheet, ListRenderItem } from "react-native";
import { ActivityIndicator, Text, useTheme } from "react-native-paper";
import { BottleCard } from "../BottleCard";
import { useSearchProducts } from "../../api/lcbo";
import { ProductModel } from "../../api/openapi";
import { useAddWatchdog, useDeleteWatchdog } from "../../api/watchdogs";
import { useGetSettings } from "../../api/settings";
import Searchbar from "../../design-system/SearchBar";
import { getLocaleString } from "../../utils/getLocaleString";
import BottleButton from "../BottleButton";
import SwapPortal from "./SwapPortal";
import BottleLoader from "../BottleLoader";


type BottleSearchProps = {
  onAdd?: () => void;
  onSearch?: () => void;
};

const BottleSearch = ({onAdd, onSearch}: BottleSearchProps) => {
  const [input, setInput] = useState('');
  const [query, setQuery] = useState('');
  const [skip, setSkip] = useState(0);
  const [searchResults, setSearchResults] = useState<ProductModel[]>([]);
  const [totalResults, setTotalResults] = useState<number>();
  const [productLoading, setProductLoading] = useState<string | null>();
  const [watchdogForSwap, setWatchdogForSwap] = useState<ProductModel>();

  const theme = useTheme();
  const addWatchdog = useAddWatchdog();
  const deleteWatchdog = useDeleteWatchdog();
  const searchProducts = useSearchProducts(
    {
      query,
      skip,
    },
    !!query,
  );
  const {data: settings} = useGetSettings();

  const watchdogsAvailable = settings
    ? settings.maxWatchdogs - settings.watchdogs.length
    : 0;

  const productIds = settings?.watchdogs.map(watchdog => watchdog.id) ?? [];

  useEffect(() => {
    if (searchProducts.isSuccess && searchProducts.data) {
      setSearchResults(searchResults.concat(searchProducts.data.products));
      setTotalResults(searchProducts.data.total);
    }
  }, [searchProducts.isSuccess && searchProducts.data]);

  const addProduct = async (product: ProductModel) => {
    setProductLoading(product.id);
    await addWatchdog.mutateAsync({product});
    setProductLoading(null);
    if (onAdd) {
      onAdd();
    }
  };

  const showSwapWatchdog = (product: ProductModel) => {
    setWatchdogForSwap(product);
  };

  const closeSwapWatchdog = () => {
    setProductLoading(null);
    setWatchdogForSwap(undefined);
  }

  const swapWatchdog = async (watchdogIdToDelete: string) => {
    setWatchdogForSwap(undefined);
    if (watchdogForSwap) {
      setProductLoading(watchdogForSwap?.id);
      await deleteWatchdog.mutateAsync(watchdogIdToDelete);
      await addWatchdog.mutateAsync({product: watchdogForSwap});
    }
    setProductLoading(null);
  };

  const clear = () => {
    setInput('');
    setQuery('');
    setTotalResults(0);
    setSearchResults([]);
  };

  const search = () => {
    if (onSearch) {
      onSearch();
    }
    setSearchResults([]);
    setTotalResults(0);
    setQuery(input.trim());
    setSkip(0);
  };

  const onEndReached = () => {
    if (searchResults.length !== totalResults && !searchProducts.isFetching)
      setSkip(searchResults.length);
  };

  const Loadingcomponent = (
    <ActivityIndicator
      animating={searchProducts.isFetching}
      size="large"
      style={styles.loading}
    />
  );

  const renderItem: ListRenderItem<ProductModel> = ({item}) => {
    const isActiveWatchdog = productIds.includes(item.id);
    return (
      <BottleCard
        key={item.id}
        bottomAction={
          <BottleButton
            style={styles.itemButton}
            icon={
              isActiveWatchdog
                ? ''
                : watchdogsAvailable
                ? 'plus'
                : 'swap-horizontal'
            }
            accessibilityLabel={getLocaleString('track')}
            onPress={
              watchdogsAvailable
                ? () => addProduct(item)
                : () => showSwapWatchdog(item)
            }
            disabled={!!productLoading || isActiveWatchdog}
            loading={productLoading == item.id}
            mode="contained">
            {isActiveWatchdog
              ? getLocaleString('active')
              : watchdogsAvailable
              ? getLocaleString('track')
              : getLocaleString('swap')}
          </BottleButton>
        }
        product={item}
      />
    );
  };

  return (
    <View style={styles.container}>
      <Searchbar
        autoComplete="off"
        placeholder={getLocaleString('bottleSearchExample')}
        onChangeText={setInput}
        value={input}
        onIconPress={search}
        onSubmitEditing={search}
        clearIcon="close"
        onClearIconPress={clear}
        style={styles.searchBar}
        mode="bar"
        inputStyle={{
          color: theme.colors.onSurface,
          fontSize: 18,
        }}
        placeholderTextColor="#eeeeee"
      />
      {!searchResults.length && searchProducts.isFetching && (
        <View style={{margin: 'auto'}}>
          <BottleLoader size="large" />
        </View>
      )}

      {!searchResults.length &&
        !searchProducts.isFetching &&
        searchProducts.isSuccess && (
          <View style={{margin: 'auto'}}>
            <Text style={{textAlign: 'center'}} variant="displaySmall">
              {getLocaleString('noResults')}
            </Text>
            <Text
              style={{textAlign: 'center', paddingTop: 8}}
              variant="titleLarge">
              {getLocaleString('pleaseAlterYourSearch')}
            </Text>
          </View>
        )}

      {!!searchResults.length && (
        <FlatList
          data={searchResults}
          renderItem={renderItem}
          keyExtractor={product => product.id}
          onEndReached={onEndReached}
          onEndReachedThreshold={1}
          style={styles.results}
          ListFooterComponent={
            searchResults.length && searchProducts.isFetching
              ? Loadingcomponent
              : null
          }
          ListFooterComponentStyle={styles.footer}
        />
      )}
      {!!watchdogForSwap && (
        <SwapPortal
          product={watchdogForSwap}
          loading={!!productLoading}
          watchdogs={settings?.watchdogs || []}
          swapWatchdog={swapWatchdog}
          close={closeSwapWatchdog}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexGrow: 1,
    height: '100%',
  },
  loading: {
    flexGrow: 1,
    margin: 'auto',
  },
  results: {
    height: '100%',
  },
  searchBar: {
    backgroundColor: 'black',
    borderBottomWidth: 1,
    borderBottomColor: 'white',
    marginBottom: 10,
  },
  footer: {
    margin: 'auto',
    height: 80,
  },
  itemButton: {
    width: '100%',
    padding: 0,
  },
});

export default BottleSearch;
