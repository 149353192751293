import { Platform, PlatformOSType } from "react-native"
import { appTheme } from "./theme";

export const MOBILE_PLATFORMS: PlatformOSType[] = ["android", "ios"];
export const IS_MOBILE = MOBILE_PLATFORMS.includes(Platform.OS);
export const IS_WEB = Platform.OS === "web";

export const REVENUECAT_PUBLIC_APP_STORE_API_KEY = 'appl_bIogNfmRsSqxccEgoOgjYIsEkwK';
export const REVENUECAT_PUBLIC_PLAY_STORE_API_KEY = 'goog_QGdGWFfOWJkmDzTHNwRuBqcVWdK';
export const REVENUECAT_STRIPE_API_KEY = 'strp_JfkKOQjZKlQxwXAuDYxiBFZBWoe';

export const PRIVACY_POLICY_URL = 'https://bottlehounds.ca/privacy';
export const TERMS_OF_USE_URL = 'https://bottlehounds.ca/tac';
export const FAQS_URL = 'https://bottlehounds.ca/faqs';

export const STOCK_STATUS_COLOR = {
  inStock: appTheme.colors.success,
  lowStock: appTheme.colors.warning,
  noStock: appTheme.colors.noStock,
};

export const STOCK_STATUS_ICON_SOURCE = {
  inStock: 'bottle-wine',
  lowStock: 'alert-outline',
  noStock: 'skull-outline',
};

