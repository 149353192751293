import { Modal, Portal, Surface, Text } from "react-native-paper";
import { getLocaleNodes, getLocaleString } from "../../../utils/getLocaleString";
import { ScrollView, StyleSheet, View } from "react-native";
import BottleButton from "../../../components/BottleButton";
import { useEffect, useState } from "react";
import { useAppTheme } from "../../../theme";
import Purchases, { PRORATION_MODE, PurchasesOfferings, PurchasesPackage } from "react-native-purchases";
import { SUBSCRIPTION_PATH, useGetSubscription } from "../../../api/subscription";
import { IS_MOBILE } from "../../../constants";
import PlanCard from "../../Subscribe/PlanCard";
import { SubscriptionPlanEnum } from "../../../api/openapi";
import { queryClient } from "../../../api/client";
import { SETTINGS_API_PATH, useGetSettings } from "../../../api/settings";
import BottleLoader from "../../../components/BottleLoader";
import Keyword from "../../../components/Keyword";
import {SubscriptionPlan} from '../../../api/openapi';


const PLAN_STRINGS = {
  premium: 'Premium',
  standard: 'Standard',
  lite: 'Lite',
};


const ChangePlan = () => {
  const theme = useAppTheme();
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<SubscriptionPlanEnum>();
  const [offerings, setOfferings] = useState<PurchasesOfferings>();

  const {isLoading: isSettingsLoading, data: settingsData} = useGetSettings();
  const {isLoading: isSubscriptionLoading, data: subscriptionData} =
    useGetSubscription(IS_MOBILE);

  const packages = offerings?.current?.availablePackages || [];
  const features = (offerings?.current?.metadata?.features || {}) as {
    [key: string]: string[];
  };

  const logIn = async (customerId: string) => {
    await Purchases.logIn(customerId);
  };

  const getOfferings = async () => {
    await logIn(subscriptionData?.customerId as string);
    const offerings = await Purchases.getOfferings();
    if (
      offerings.current !== null &&
      offerings.current.availablePackages.length !== 0
    ) {
      setOfferings(offerings);
    } 
  };
  
  const invalidateCaches = () => {
    queryClient.invalidateQueries({queryKey: [SUBSCRIPTION_PATH]});
    queryClient.invalidateQueries({queryKey: [SETTINGS_API_PATH]});
  };

  const refresh = () => {
    if (subscriptionData?.plan !== selectedPlan) {
      setTimeout(refresh, 1500);
      invalidateCaches();
      return;
    }
    setLoading(false);
  }
  

  const subscribe = async () => {
    setLoading(true);
    await logIn(subscriptionData?.customerId as string);
    await Purchases.logIn(subscriptionData?.customerId as string);
    const selectedPackage = offerings?.current?.availablePackages.find(
      offering => offering.identifier === selectedPlan
    );
    
    try {   
      await Purchases.purchasePackage(
        selectedPackage as PurchasesPackage,
        null,
        subscriptionData?.productId && subscriptionData.store === 'play_store'
          ? {
            oldProductIdentifier: subscriptionData.productId,
            prorationMode: PRORATION_MODE.IMMEDIATE_WITH_TIME_PRORATION
          }
          : null,
      );
      refresh();
    } catch (error) {
      console.error(error);
      setShowModal(false);
      setSelectedPlan(undefined);
      setLoading(false);
    }
  }

  useEffect(() => {
    if (subscriptionData?.customerId) {
      getOfferings().catch(e => console.error(e));
    }
  }, [subscriptionData]);

  return (
    <>
      <BottleButton
        disabled={showModal}
        mode="contained"
        onPress={() => setShowModal(true)}
        style={{marginBottom: 30}}>
        {getLocaleString('changePlan')}
      </BottleButton>
      <Portal>
        <Modal
          visible={showModal}
          onDismiss={() => setShowModal(false)}
          style={styles.modal}
          contentContainerStyle={styles.modal}>
          <Surface
            elevation={1}
            style={{
              borderColor: theme.colors.primary10,
              borderWidth: 1,
              borderTopColor: theme.colors.primary,
              borderTopWidth: 4,
              backgroundColor: theme.colors.primary5,
              ...styles.content,
            }}>
            <Text
              variant="headlineSmall"
              style={{
                fontWeight: 'bold',
                marginBottom: 20,
              }}>
              {getLocaleString('changePlan')}
            </Text>

            <View style={styles.content}>
              {loading && (
                <View
                  style={{
                    padding: 4,
                    marginTop: 8,
                    width: '100%',
                    height: 400,
                    maxHeight: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                  <BottleLoader size="large" />
                </View>
              )}
              {packages.length > 0 && !loading && (
                <>
                  <Text variant="titleLarge">
                    {getLocaleNodes('currentPlan', {
                      plan: (
                        <Keyword variant="titleLarge" key="plan">
                          {
                            PLAN_STRINGS[
                              settingsData?.subscription
                                ?.plan as SubscriptionPlan
                            ]
                          }
                        </Keyword>
                      ),
                    })}
                  </Text>

                  <ScrollView
                    style={{
                      padding: 4,
                      marginTop: 16,
                      width: '100%',
                      height: 400,
                      maxHeight: '100%',
                    }}>
                    {packages
                      .filter(
                        offering =>
                          offering.identifier.split('_')[0] !==
                          subscriptionData?.plan,
                      )
                      .map(offering => (
                        <PlanCard
                          key={offering.identifier}
                          plan={{
                            plan: offering.identifier as SubscriptionPlanEnum,
                            name: offering.product.title.replace(
                              ' (Bottle Hounds)',
                              '',
                            ),
                            description: offering.product.description,
                            features: features[offering.identifier] || [],
                            unitAmount: offering.product.price,
                            priceString: offering.product.priceString,
                          }}
                          selected={selectedPlan === offering.identifier}
                          setPlan={setSelectedPlan}
                        />
                      ))}
                  </ScrollView>
                </>
              )}
            </View>

            <View style={styles.actions}>
              <BottleButton
                textColor={theme.colors.secondary}
                disabled={loading}
                onPress={() => setShowModal(false)}>
                {getLocaleString('cancel')}
              </BottleButton>
              <BottleButton
                disabled={!selectedPlan || isSubscriptionLoading || loading}
                loading={loading}
                onPress={subscribe}>
                {getLocaleString('subscribe')}
              </BottleButton>
            </View>
          </Surface>
        </Modal>
      </Portal>
    </>
  );
}


const styles = StyleSheet.create({
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 20,
  },
  content: {
    width: '100%',
    minHeight: 100,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'space-between',
    padding: 12,
    textAlign: 'center',
  },
  modal: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 4,
    paddingRight: 4,
    width: '100%',
    maxWidth: 500,
    margin: 'auto',
  },
});


export default ChangePlan;