/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  HTTPValidationError,
  ProductModel,
  ProductSearchResultsModel,
  SettingsRequestModel,
  SettingsResponseModel,
  SubscriptionCheckoutRequestModel,
  SubscriptionCheckoutResponseModel,
  SubscriptionConfigResponseModel,
  SubscriptionCreateRequestModel,
  SubscriptionCreateResponseModel,
  SubscriptionPlansResponseModel,
  SubscriptionPortalSessionModel,
  WatchdogRequestModel,
} from '../models';
import {
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
    ProductModelFromJSON,
    ProductModelToJSON,
    ProductSearchResultsModelFromJSON,
    ProductSearchResultsModelToJSON,
    SettingsRequestModelFromJSON,
    SettingsRequestModelToJSON,
    SettingsResponseModelFromJSON,
    SettingsResponseModelToJSON,
    SubscriptionCheckoutRequestModelFromJSON,
    SubscriptionCheckoutRequestModelToJSON,
    SubscriptionCheckoutResponseModelFromJSON,
    SubscriptionCheckoutResponseModelToJSON,
    SubscriptionConfigResponseModelFromJSON,
    SubscriptionConfigResponseModelToJSON,
    SubscriptionCreateRequestModelFromJSON,
    SubscriptionCreateRequestModelToJSON,
    SubscriptionCreateResponseModelFromJSON,
    SubscriptionCreateResponseModelToJSON,
    SubscriptionPlansResponseModelFromJSON,
    SubscriptionPlansResponseModelToJSON,
    SubscriptionPortalSessionModelFromJSON,
    SubscriptionPortalSessionModelToJSON,
    WatchdogRequestModelFromJSON,
    WatchdogRequestModelToJSON,
} from '../models';

export interface CreateSubscriptionCheckoutSubscriptionsCheckoutPostRequest {
    SubscriptionCheckoutRequestModel: SubscriptionCheckoutRequestModel;
}

export interface CreateSubscriptionSubscriptionsPostRequest {
    SubscriptionCreateRequestModel: SubscriptionCreateRequestModel;
}

export interface DeleteWatchdogsWatchdogIdDeleteRequest {
    watchdog_id: string;
}

export interface GetPlaceMapsPlacesPlaceIdGetRequest {
    place_id: string;
}

export interface GetPlacesMapsPlacesGetRequest {
    query: string;
    country?: string;
}

export interface GetProductLcboProductsProductIdGetRequest {
    product_id: string;
}

export interface GetProductsLcboProductsGetRequest {
    query: string;
    skip?: number;
    limit?: number;
}

export interface PostSettingsPatchRequest {
    SettingsRequestModel: SettingsRequestModel;
}

export interface PostWatchdogsPostRequest {
    WatchdogRequestModel: WatchdogRequestModel;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     * Create Subscription Checkout
     */
    async createSubscriptionCheckoutSubscriptionsCheckoutPostRaw(requestParameters: CreateSubscriptionCheckoutSubscriptionsCheckoutPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SubscriptionCheckoutResponseModel>> {
        if (requestParameters.SubscriptionCheckoutRequestModel === null || requestParameters.SubscriptionCheckoutRequestModel === undefined) {
            throw new runtime.RequiredError('SubscriptionCheckoutRequestModel','Required parameter requestParameters.SubscriptionCheckoutRequestModel was null or undefined when calling createSubscriptionCheckoutSubscriptionsCheckoutPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/subscriptions/checkout`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SubscriptionCheckoutRequestModelToJSON(requestParameters.SubscriptionCheckoutRequestModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SubscriptionCheckoutResponseModelFromJSON(jsonValue));
    }

    /**
     * Create Subscription Checkout
     */
    async createSubscriptionCheckoutSubscriptionsCheckoutPost(requestParameters: CreateSubscriptionCheckoutSubscriptionsCheckoutPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SubscriptionCheckoutResponseModel> {
        const response = await this.createSubscriptionCheckoutSubscriptionsCheckoutPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create Subscription
     */
    async createSubscriptionSubscriptionsPostRaw(requestParameters: CreateSubscriptionSubscriptionsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SubscriptionCreateResponseModel>> {
        if (requestParameters.SubscriptionCreateRequestModel === null || requestParameters.SubscriptionCreateRequestModel === undefined) {
            throw new runtime.RequiredError('SubscriptionCreateRequestModel','Required parameter requestParameters.SubscriptionCreateRequestModel was null or undefined when calling createSubscriptionSubscriptionsPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/subscriptions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SubscriptionCreateRequestModelToJSON(requestParameters.SubscriptionCreateRequestModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SubscriptionCreateResponseModelFromJSON(jsonValue));
    }

    /**
     * Create Subscription
     */
    async createSubscriptionSubscriptionsPost(requestParameters: CreateSubscriptionSubscriptionsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SubscriptionCreateResponseModel> {
        const response = await this.createSubscriptionSubscriptionsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete
     */
    async deleteWatchdogsWatchdogIdDeleteRaw(requestParameters: DeleteWatchdogsWatchdogIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.watchdog_id === null || requestParameters.watchdog_id === undefined) {
            throw new runtime.RequiredError('watchdog_id','Required parameter requestParameters.watchdog_id was null or undefined when calling deleteWatchdogsWatchdogIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/watchdogs/{watchdog_id}`.replace(`{${"watchdog_id"}}`, encodeURIComponent(String(requestParameters.watchdog_id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Delete
     */
    async deleteWatchdogsWatchdogIdDelete(requestParameters: DeleteWatchdogsWatchdogIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.deleteWatchdogsWatchdogIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Config
     */
    async getConfigSubscriptionsConfigGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SubscriptionConfigResponseModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/subscriptions/config`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SubscriptionConfigResponseModelFromJSON(jsonValue));
    }

    /**
     * Get Config
     */
    async getConfigSubscriptionsConfigGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SubscriptionConfigResponseModel> {
        const response = await this.getConfigSubscriptionsConfigGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get Place
     */
    async getPlaceMapsPlacesPlaceIdGetRaw(requestParameters: GetPlaceMapsPlacesPlaceIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.place_id === null || requestParameters.place_id === undefined) {
            throw new runtime.RequiredError('place_id','Required parameter requestParameters.place_id was null or undefined when calling getPlaceMapsPlacesPlaceIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/maps/places/{place_id}`.replace(`{${"place_id"}}`, encodeURIComponent(String(requestParameters.place_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Get Place
     */
    async getPlaceMapsPlacesPlaceIdGet(requestParameters: GetPlaceMapsPlacesPlaceIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.getPlaceMapsPlacesPlaceIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Places
     */
    async getPlacesMapsPlacesGetRaw(requestParameters: GetPlacesMapsPlacesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.query === null || requestParameters.query === undefined) {
            throw new runtime.RequiredError('query','Required parameter requestParameters.query was null or undefined when calling getPlacesMapsPlacesGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.country !== undefined) {
            queryParameters['country'] = requestParameters.country;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/maps/places`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Get Places
     */
    async getPlacesMapsPlacesGet(requestParameters: GetPlacesMapsPlacesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.getPlacesMapsPlacesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Plans
     */
    async getPlansSubscriptionsPlansGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SubscriptionPlansResponseModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/subscriptions/plans`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SubscriptionPlansResponseModelFromJSON(jsonValue));
    }

    /**
     * Get Plans
     */
    async getPlansSubscriptionsPlansGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SubscriptionPlansResponseModel> {
        const response = await this.getPlansSubscriptionsPlansGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get Product
     */
    async getProductLcboProductsProductIdGetRaw(requestParameters: GetProductLcboProductsProductIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductModel>> {
        if (requestParameters.product_id === null || requestParameters.product_id === undefined) {
            throw new runtime.RequiredError('product_id','Required parameter requestParameters.product_id was null or undefined when calling getProductLcboProductsProductIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/lcbo/products/{product_id}`.replace(`{${"product_id"}}`, encodeURIComponent(String(requestParameters.product_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductModelFromJSON(jsonValue));
    }

    /**
     * Get Product
     */
    async getProductLcboProductsProductIdGet(requestParameters: GetProductLcboProductsProductIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductModel> {
        const response = await this.getProductLcboProductsProductIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Products
     */
    async getProductsLcboProductsGetRaw(requestParameters: GetProductsLcboProductsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductSearchResultsModel>> {
        if (requestParameters.query === null || requestParameters.query === undefined) {
            throw new runtime.RequiredError('query','Required parameter requestParameters.query was null or undefined when calling getProductsLcboProductsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.skip !== undefined) {
            queryParameters['skip'] = requestParameters.skip;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/lcbo/products`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductSearchResultsModelFromJSON(jsonValue));
    }

    /**
     * Get Products
     */
    async getProductsLcboProductsGet(requestParameters: GetProductsLcboProductsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductSearchResultsModel> {
        const response = await this.getProductsLcboProductsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get
     */
    async getSettingsGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SettingsResponseModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/settings`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SettingsResponseModelFromJSON(jsonValue));
    }

    /**
     * Get
     */
    async getSettingsGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SettingsResponseModel> {
        const response = await this.getSettingsGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get Subscriptions Portal
     */
    async getSubscriptionsPortalSubscriptionsPortalGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SubscriptionPortalSessionModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/subscriptions/portal`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SubscriptionPortalSessionModelFromJSON(jsonValue));
    }

    /**
     * Get Subscriptions Portal
     */
    async getSubscriptionsPortalSubscriptionsPortalGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SubscriptionPortalSessionModel> {
        const response = await this.getSubscriptionsPortalSubscriptionsPortalGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Post
     */
    async postSettingsPatchRaw(requestParameters: PostSettingsPatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SettingsResponseModel>> {
        if (requestParameters.SettingsRequestModel === null || requestParameters.SettingsRequestModel === undefined) {
            throw new runtime.RequiredError('SettingsRequestModel','Required parameter requestParameters.SettingsRequestModel was null or undefined when calling postSettingsPatch.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/settings`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: SettingsRequestModelToJSON(requestParameters.SettingsRequestModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SettingsResponseModelFromJSON(jsonValue));
    }

    /**
     * Post
     */
    async postSettingsPatch(requestParameters: PostSettingsPatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SettingsResponseModel> {
        const response = await this.postSettingsPatchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Post
     */
    async postWatchdogsPostRaw(requestParameters: PostWatchdogsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SettingsResponseModel>> {
        if (requestParameters.WatchdogRequestModel === null || requestParameters.WatchdogRequestModel === undefined) {
            throw new runtime.RequiredError('WatchdogRequestModel','Required parameter requestParameters.WatchdogRequestModel was null or undefined when calling postWatchdogsPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/watchdogs`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WatchdogRequestModelToJSON(requestParameters.WatchdogRequestModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SettingsResponseModelFromJSON(jsonValue));
    }

    /**
     * Post
     */
    async postWatchdogsPost(requestParameters: PostWatchdogsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SettingsResponseModel> {
        const response = await this.postWatchdogsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
