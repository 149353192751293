import {ScrollView, StyleSheet, View} from 'react-native';
import BottleTitle from '../../components/BottleTitle';
import { getLocaleNodes, getLocaleString } from '../../utils/getLocaleString';
import { Modal, Portal, Surface, Text, TextInput } from 'react-native-paper';
import BottleButton from '../../components/BottleButton';
import { useState } from 'react';
import { useDeleteAccount } from '../../api/account';
import { getCurrentUser } from 'aws-amplify/auth';
import { useAppTheme } from '../../theme';
import { signOutUser } from '../../utils/user';


const CONFIRM_TEXT = 'permanently delete';


const DangerZone = () => {
  const theme = useAppTheme();

  const [input, setInput] = useState('');
  const [loading, setLoading] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  
  const deleteAccount = useDeleteAccount();

  const confirmed = input === CONFIRM_TEXT;
  
  const confirmDelete = async () => {
    setLoading(true);
    try {
      await deleteAccount.mutateAsync(input);
      signOutUser();
    } catch (error) {
      getCurrentUser().catch(() => signOutUser);
      setLoading(false);
      setShowConfirmDelete(false);
    }

  };

  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        height: '100%',
        marginTop: 20,
        padding: 4,
      }}>
      <ScrollView style={{width: '100%'}}>
        <View style={styles.section}>
          <BottleTitle
            variant="headlineMedium"
            style={{marginBottom: 10, textAlign: 'center'}}>
            {getLocaleString('deleteAccount')}
          </BottleTitle>

          <View
            style={{
              maxWidth: 700,
              width: '100%',
              margin: 'auto',
              alignItems: 'center',
            }}>
            <Text
              variant="titleLarge"
              style={{textAlign: 'center', marginTop: 20, marginBottom: 30}}>
              {getLocaleString('deleteAccountDescription')}
            </Text>
            <BottleButton
              buttonColor={theme.colors.error + 'DD'}
              loading={loading}
              disabled={loading || showConfirmDelete}
              mode="contained"
              onPress={() => setShowConfirmDelete(true)}>
              {getLocaleString('deleteAccount')}
            </BottleButton>
          </View>
        </View>
      </ScrollView>

      <Portal>
        <Modal
          visible={showConfirmDelete}
          onDismiss={() => setShowConfirmDelete(false)}
          style={styles.modal}
          contentContainerStyle={styles.modal}>
          <Surface
            elevation={1}
            style={{
              borderColor: theme.colors.primary10,
              borderWidth: 1,
              borderTopColor: theme.colors.primary,
              borderTopWidth: 4,
              backgroundColor: theme.colors.primary5,
              ...styles.content,
            }}>
            <Text
              variant="headlineSmall"
              style={{
                color: theme.colors.error + 'DD',
                fontWeight: 'bold',
                marginBottom: 20,
              }}>
              {getLocaleString('deleteAccount')}
            </Text>

            <Text
              style={{
                marginBottom: 30,
                textAlign: 'center',
                fontSize: 20,
              }}
              variant="titleLarge">
              {getLocaleNodes('deleteInstructions', {
                confirmText: (
                  <Text variant="titleLarge" style={{fontWeight: 'bold'}}>
                    {CONFIRM_TEXT}
                  </Text>
                ),
              })}
            </Text>

            <TextInput
              dense={true}
              mode="outlined"
              onChangeText={setInput}
              returnKeyType="next"
              value={input}
              disabled={loading}
              placeholder={CONFIRM_TEXT}
              style={{marginBottom: 10}}
              placeholderTextColor={theme.colors.onSurfaceDisabled}
            />

            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginTop: 20,
              }}>
              <BottleButton
                textColor={theme.colors.secondary}
                disabled={loading}
                onPress={() => setShowConfirmDelete(false)}>
                {getLocaleString('cancel')}
              </BottleButton>
              <BottleButton
                textColor={theme.colors.error}
                disabled={loading || !confirmed}
                loading={loading}
                onPress={confirmDelete}>
                {getLocaleString('delete')}
              </BottleButton>
            </View>
          </Surface>
        </Modal>
      </Portal>
    </View>
  );
};

const styles = StyleSheet.create({
  content: {
    width: '100%',
    minHeight: 100,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'space-between',
    padding: 12,
    textAlign: 'center',
  },
  modal: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 4,
    paddingRight: 4,
    width: '100%',
    maxWidth: 500,
    margin: 'auto',
  },
  section: {
    marginBottom: 10,
    width: '100%',
    flex: 1,
  },
  title: {
    marginBottom: 10,
    textAlign: 'center',
    fontSize: 20,
  },
});

export default DangerZone;
