import { useState } from "react";
import { SafeAreaView } from "react-native-safe-area-context";
import { FlatList, View, StyleSheet, ListRenderItem, RefreshControl } from "react-native";
import { Text } from "react-native-paper";
import { HomeStackScreenProps } from "../Home/types";
import { SETTINGS_API_PATH, useGetSettings } from "../../api/settings";
import { WatchdogSettingsModel } from "../../api/openapi";
import { getLocaleNodes, getLocaleString } from "../../utils/getLocaleString";
import BottleLoader from "../../components/BottleLoader";
import BottleButton from "../../components/BottleButton";
import Keyword from "../../components/Keyword";
import WatchdogCard from "./WatchdogCard";
import { queryClient } from "../../api/client";
import { WATCHDOG_INVENTORY_KEY } from "../../api/inventory";
import Search from "../Search";


const Watchdogs = ({ navigation }: HomeStackScreenProps<'Watchdogs'>) => {
  const { data: settings, isLoading } = useGetSettings();
  const watchdogsAvailable = (
    settings ?
    settings.maxWatchdogs - settings.watchdogs.length :
    0
  );
  
  const onRefresh = () => {
    settings?.watchdogs.forEach((watchdog) => queryClient.invalidateQueries({
      queryKey: [WATCHDOG_INVENTORY_KEY, watchdog.type, watchdog.id]
    }))
    queryClient.invalidateQueries({queryKey: [SETTINGS_API_PATH]});
  }

  const onShowInventory = (productId: string) => {
    navigation.push('WatchdogInventory', {id: productId});
  };

  const renderItem: ListRenderItem<WatchdogSettingsModel> = ({ item }) => {
    return (
      <WatchdogCard
        onShowInventory={onShowInventory}
        watchdog={item}
      />
    );
  };

  return (
    <SafeAreaView style={styles.container}>
      {isLoading ? <BottleLoader size="large" /> : null}

      {!isLoading && settings?.watchdogs && settings.watchdogs.length === 0 && (
        <Search />
      )}

      {!isLoading && !!settings?.watchdogs.length && (
        <>
          <FlatList
            data={settings?.watchdogs}
            renderItem={renderItem}
            keyExtractor={item => item.id as string}
            style={styles.results}
            refreshControl={
              <RefreshControl refreshing={isLoading} onRefresh={onRefresh} />
            }
          />

          <View style={styles.addButton}>
            {watchdogsAvailable > 0 && (
              <Text
                variant="titleLarge"
                style={{paddingBottom: 8, textAlign: 'center'}}>
                {getLocaleNodes('youHaveNMoreWatchdogsAvailable', {
                  watchdogsAvailable: (
                    <Keyword key="watchdogs-available">
                      {watchdogsAvailable}
                    </Keyword>
                  ),
                  watchdogs: <Keyword key="watchdogs">Watchdogs</Keyword>,
                })}
              </Text>
            )}
            <BottleButton
              icon="plus"
              mode="contained"
              onPress={() => {
                navigation.push('Search');
              }}>
              {getLocaleString('addWatchdog')}
            </BottleButton>
          </View>
        </>
      )}
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "stretch",
    height: "100%",
  },
  addButton: {
    marginTop: 10,
    marginBottom: 15,
  },
  results: {
    flex: 1
  },
  loading: {
    margin: "auto",
  },
});

export default Watchdogs;
