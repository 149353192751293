import { StyleSheet } from "react-native";
import { ActivityIndicator, ActivityIndicatorProps } from "react-native-paper";

const BottleLoader = ({size = "small", ...props}: ActivityIndicatorProps) => {
  return (
    <ActivityIndicator
      animating={true}
      size={size}
      style={styles.loading}
      {...props}
    />
  );
};

const styles = StyleSheet.create({
  loading: {
    margin: 'auto',
  },
});

export default BottleLoader;
