import {ScrollView, StyleSheet, View} from 'react-native';
import {Subheading, TextInput, useTheme} from 'react-native-paper';
import {getLocaleString} from '../../utils/getLocaleString';
import {useState} from 'react';
import BottleTitle from '../../components/BottleTitle';
import BottleButton from '../../components/BottleButton';
import { updatePassword } from 'aws-amplify/auth';

const SecuritySettings = () => {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [oldPassword, setOldPassword] = useState('');
  const [passwordError, setPasswordError] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const {colors} = useTheme();

  const submitDisabled = !oldPassword || !newPassword || newPassword !== confirmNewPassword || loading;

  const confirmPasswordError = !!(
    confirmNewPassword && confirmNewPassword !== newPassword
  );
  
  const submit = async () => {
    setLoading(true);
    setError('');
    setPasswordError('');
    setSuccess(false);

    try {
      await updatePassword({oldPassword, newPassword});
      setSuccess(true);
    } catch (error) {
      
      setOldPassword('');
      setNewPassword('');
      setConfirmNewPassword('');
      switch ((error as {name: string}).name) {
        case 'InvalidPasswordException':
          setError(getLocaleString('invalidPassword'));
          break;
        default:
          setError(getLocaleString('sww'));
          break;
      }
    }
    setLoading(false);
  };

  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        height: '100%',
        marginTop: 20,
        padding: 4,
      }}>
      <ScrollView style={{width: '100%'}}>
        <View style={{maxWidth: 600, width: '100%'}}>
          <View style={styles.section}>
            <BottleTitle variant="headlineMedium" style={{marginBottom: 10}}>
              {getLocaleString('changePassword')}
            </BottleTitle>
            {!!error && (
              <Subheading style={{color: colors.error, textAlign: 'center'}}>
                {error}
              </Subheading>
            )}

            {!!success && (
              <Subheading style={{textAlign: 'center'}}>
                {getLocaleString('passwordResetSuccessful')}
              </Subheading>
            )}

            <TextInput
              autoComplete="off"
              dense={true}
              error={!!passwordError || !!(!oldPassword && newPassword)}
              label={getLocaleString('oldPassword')}
              mode="outlined"
              onChangeText={setOldPassword}
              secureTextEntry={true}
              value={oldPassword}
              disabled={loading}
              style={styles.input}
            />

            <TextInput
              autoComplete="off"
              dense={true}
              label={getLocaleString('password')}
              mode="outlined"
              onChangeText={setNewPassword}
              secureTextEntry={true}
              value={newPassword}
              disabled={loading}
              style={styles.input}
            />

            <TextInput
              autoComplete="off"
              dense={true}
              label={getLocaleString('confirmPassword')}
              mode="outlined"
              onChangeText={setConfirmNewPassword}
              secureTextEntry={true}
              value={confirmNewPassword}
              disabled={loading}
              blurOnSubmit={true}
              onSubmitEditing={submit}
              returnKeyType="send"
              error={confirmPasswordError}
              style={styles.input}
            />
            <BottleButton
              loading={loading}
              disabled={submitDisabled}
              mode="contained"
              onPress={submit}>
              {getLocaleString('changePassword')}
            </BottleButton>
          </View>
        </View>
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    width: '100%',
    paddingTop: 4,
    paddingLeft: 4,
    paddingBottom: 4,
  },
  section: {
    marginBottom: 10,
    width: '100%',
  },
  input: {
    marginBottom: 10,
  }
});

export default SecuritySettings;
